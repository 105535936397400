import React, { useImperativeHandle, Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ICONdeputy from '../../../assets/icons/deputy.svg';
import { useMemoizedFn } from 'ahooks';
import axios from '../../../axios-custom';
import { awaitWrap, encryptStr } from '../../../utils/util';
import store from '../../../state/store';
import unreadlettersIcon from '../../../assets/icons/unread_letters-icon.svg';

const { useModel } = store;

const PendingRequest = React.forwardRef((props, ref) => {
	const { t } = useTranslation();
	const history = useHistory();
	// eslint-disable-next-line no-unused-vars
	const [state, dispatchers] = useModel('global');
	const { showLoading, hideLoading, toastMessageShow } = dispatchers;

	useImperativeHandle(ref, () => ({
		//
	}));

	const gotoDetailPage = useMemoizedFn(async (obj) => {
		// history.push(`/maintenance`);
		// return;
		showLoading();
		let checkobj = {
			email: obj.receiveremail,
			nc: obj.qmnum,
			report: obj.report,
			lang: 'E',
			imstatus: 'O',
		};
		const checkresponse = await axios.post(
			'/feedbackServices/portalSap/detailedPage',
			checkobj
		);
		if (checkresponse.data.edescrtext.includes('//Closed')) {
			//
			let refDocNo = '';
			if (obj.report === 'YNCM_SUPPLIER_FEEDBACK') {
				refDocNo = obj.po;
			} else if (obj.report === 'YNCM_HAULIER_FEEDBACK') {
				if (
					obj.shipment !== '' &&
					obj.salesorder !== '' &&
					obj.delivery !== ''
				) {
					refDocNo = obj.shipment;
				} else if (
					obj.shipment === '' &&
					obj.salesorder !== '' &&
					obj.delivery !== ''
				) {
					refDocNo = obj.salesorder;
				} else if (
					obj.shipment === '' &&
					obj.salesorder === '' &&
					obj.delivery !== ''
				) {
					refDocNo = obj.delivery;
				}
			}
			//
			let temp = {
				email: obj.receiveremail,
				nc: obj.qmnum,
				report: obj.report,
				status: 'closed',
				id: '', // 6bb076f1-a9e5-437f-80ca-710462cbbb78
				refDocNo: refDocNo,
				// typeuser: 'preview',
				typeuser: 'checkclosed',
			};
			hideLoading();
			//
			let encryptVal = encryptStr(
				`email=${temp.email}&nc=${temp.nc}&report=${temp.report}&status=${temp.status}&id=${temp.id}&refDocNo=${temp.refDocNo}&typeuser=${temp.typeuser}&from=landing`
			);
			//
			history.push(`/detail?value=${encryptVal}`);
			return;
		}
		// eslint-disable-next-line no-unused-vars
		const [err, response] = await awaitWrap(
			axios.get(`/feedbackServices/apiResource/getResource/${obj.qmnum}`)
		);
		if (response && response.data && response.data.isUnderEdit === 'true') {
			let notificationObj = {
				type: 'error',
				title: 'Error',
				description: `${t('Overview.lock1')} ${obj.qmnum} ${t(
					'Overview.lock2'
				)}`,
				duration: 5,
			};
			hideLoading();
			toastMessageShow(notificationObj);
			return;
		}
		//
		let user = JSON.parse(localStorage.getItem('NCM_User'));
		let lockResource = await axios.post(
			`/feedbackServices/apiResource/lockResource/${obj.qmnum}?qmnum=${obj.qmnum}&userid=${user.email}`,
			{}
		);
		// console.log('lockResource', lockResource);
		//
		let refDocNo = '';
		if (obj.report === 'YNCM_SUPPLIER_FEEDBACK') {
			refDocNo = obj.po;
		} else if (obj.report === 'YNCM_HAULIER_FEEDBACK') {
			if (obj.shipment !== '' && obj.salesorder !== '' && obj.delivery !== '') {
				refDocNo = obj.shipment;
			} else if (
				obj.shipment === '' &&
				obj.salesorder !== '' &&
				obj.delivery !== ''
			) {
				refDocNo = obj.salesorder;
			} else if (
				obj.shipment === '' &&
				obj.salesorder === '' &&
				obj.delivery !== ''
			) {
				refDocNo = obj.delivery;
			}
		}
		window.scrollTo(0, 0);
		//
		let status = '';
		let id = '';
		if (obj.status.includes('DRAFT')) {
			status = 'draft';
			let temp = obj.status.split('$$');
			id = temp[temp.length - 1];
		} else {
			// check draft
			let checkid = await axios.post(
				`/feedbackServices/api/getQmnumUniqueId?qmnum=${obj.qmnum}&report=${obj.report}`
			);
			console.log(111, checkid);
			if (checkid && checkid.data !== '') {
				status = 'draft';
				id = checkid.data;
			} else {
				status = 'open';
			}
		}
		//
		hideLoading();
		//
		let temp = {
			email: obj.receiveremail,
			nc: obj.qmnum,
			report: obj.report,
			status: status,
			id: id,
			refDocNo: refDocNo,
			typeuser: 'fromlanding',
		};
		console.log(temp);
		let encryptVal = encryptStr(
			`email=${temp.email}&nc=${temp.nc}&report=${temp.report}&status=${temp.status}&id=${temp.id}&refDocNo=${temp.refDocNo}&typeuser=${temp.typeuser}&from=landing`
		);
		history.push(`/detail?value=${encryptVal}`);
	});

	const reference = useMemoizedFn((obj) => {
		if (obj.report === 'YNCM_SUPPLIER_FEEDBACK') {
			return <Fragment>{obj.po}</Fragment>;
		} else if (obj.report === 'YNCM_HAULIER_FEEDBACK') {
			if (obj.shipment !== '' && obj.salesorder !== '' && obj.delivery !== '') {
				return <Fragment>{obj.shipment}</Fragment>;
			} else if (
				obj.shipment === '' &&
				obj.salesorder !== '' &&
				obj.delivery !== ''
			) {
				return <Fragment>{obj.salesorder}</Fragment>;
			} else if (
				obj.shipment === '' &&
				obj.salesorder === '' &&
				obj.delivery !== ''
			) {
				return <Fragment>{obj.delivery}</Fragment>;
			}
		}
	});

	const gotoLetter = useMemoizedFn(() => {
		props.gotoLetter();
	});

	return (
		<Fragment>
			<ul ref={ref} className={props.tabs ? 'lstLanding active' : 'lstLanding'}>
				{props.pendingData.map((item, index) => {
					return (
						<li
							onClick={() => gotoDetailPage(item)}
							className={
								item.firstrem === 'X' && item.overdue !== 'X'
									? 'pre-overdue listcard'
									: item.overdue === 'X'
									? 'overdue listcard'
									: 'listcard'
							}
							key={index}
						>
							<span className="ncnumber">
								<span>{item.qmnum}</span>
								{/* {item.nctype && item.nctype.toUpperCase() !== 'Y1' ? (
								<Fragment>
									<span> - </span>
									<span>{item.nctype}</span>
								</Fragment>
							) : (
								<Fragment />
							)} */}
							</span>
							<span className="reference">{reference(item)}</span>
							<p className="title" title={item.mreason}>
								{item.mreason}
							</p>
							<div className="customercomplaint">
								{item.nctype && item.nctype.toUpperCase() === 'Y1' ? (
									<span>Customer Complaint</span>
								) : (
									<span>&nbsp;</span>
								)}
							</div>
							<span className="date">{item.duedate}</span>
							{item.overdue === 'X' ? (
								<span className="overdue">{t('Landing.Overdue')}</span>
							) : (
								<Fragment />
							)}
							{item.status === 'OPEN' ? (
								<div className="boxTags">
									<span className="long">{t('Landing.Open')}</span>
									{item.icon === true ? (
										<div className="deputyDetail">
											<img alt="Deputy" src={ICONdeputy} />
											<p>
												{t('Overview.until1')}
												{item.todate}
												{t('Overview.until2')}
											</p>
										</div>
									) : (
										<Fragment />
									)}
								</div>
							) : (
								<div className="boxTags draft">
									<span className="long">{t('Landing.Draft')}</span>
									{item.icon === true ? (
										<div className="deputyDetail">
											<img alt="Deputy" className="draft" src={ICONdeputy} />
											<p>
												{t('Overview.until1')}
												{item.todate}
												{t('Overview.until2')}
											</p>
										</div>
									) : (
										<Fragment />
									)}
								</div>
							)}
						</li>
					);
				})}
				{props.letterslength ? (
					<li onClick={() => gotoLetter()} className="listcard unletterli">
						<img
							className="unletterliimage"
							alt="Error"
							src={unreadlettersIcon}
						/>
						<span className="unletterlitext">
							{t('Detail.newunreadletters1')}
							<span style={{ color: '#1fa1d2' }}>
								{t('Detail.newunreadletters2')}
							</span>
							{t('Detail.newunreadletters3')}
						</span>
						<span className="unletterlibutton">{t('Overview.SeeNow')}</span>
					</li>
				) : (
					<Fragment />
				)}
			</ul>
			{/* <div className="tiptext">
				<span style={{ fontWeight: '700' }}>{t('Overview.Rtiptext1')}</span>
				{t('Overview.Rtiptext2')}
			</div> */}
		</Fragment>
	);
});

export default memo(PendingRequest);
