import React, { useEffect, useState } from 'react';
import LeftDetail from './detail';
import './index.scss';
import IMAGlandingBig from '../../assets/images/landing_big.jpg';
import ICONdown from '../../assets/icons/arrow-down.svg';
import ICONup from '../../assets/icons/arrow-up.svg';
import { Collapse } from 'antd';
import '../../../node_modules/antd/lib/collapse/style/index.css';

const { Panel } = Collapse;

function DReport({ bgHandler }) {
	const [expandedSupplier, setExpandedSupplier] = useState(false);

	useEffect(() => {
		bgHandler(false, false, IMAGlandingBig, false);
	}, [bgHandler]);

	const expandIconRender = (isActive) => {
		if (isActive) {
			return <img className="accordion-icon expand" alt="" src={ICONdown} />;
		} else {
			return <img className="accordion-icon shrink" alt="" src={ICONup} />;
		}
	};

	return (
		<section className="dreport print_hide">
			<div className="supplier">
				<div className="boxHeadSupplier">
					<span>Date</span>
					<h3>09-08-2021</h3>
					<button
						className={
							expandedSupplier
								? 'btnToggleSupplier expanded'
								: 'btnToggleSupplier'
						}
						onClick={() => setExpandedSupplier(!expandedSupplier)}
					>
						<img className="expand" alt="Expand" src={ICONdown} />
						<img className="shrink" alt="Shrink" src={ICONup} />
						<span className="lessInfo">show less details</span>
						<span className="moreInfo">show more details</span>
					</button>
				</div>

				<LeftDetail expandedSupplier={expandedSupplier}></LeftDetail>
			</div>
			<div className="feedback">
				<span className="status open">
					<span>Open</span>
				</span>

				{/* <div className="boxHeadFeedback">
					<span>Createdon 123</span>
					<h3>NC 123</h3>
				</div>

				<h2>123</h2>
				<p className="description">123</p> */}

				<div>
					<h5>Your Material No.</h5>
					<ul className="lstFeedback">
						<li>
							<input
								type="text"
								id="txb_description"
								className="txbFeedback"
								value=""
								placeholder="Your Material No."
							/>
						</li>
					</ul>
					{/* 1 */}
					<Collapse
						bordered={false}
						defaultActiveKey={['1']}
						className="custom-collapse"
						expandIconPosition="end"
						ghost
						expandIcon={({ isActive }) => expandIconRender(isActive)}
					>
						<Panel header="1. Team" key="1" className="custom-panel">
							<ul className="lstFeedback">
								<li>
									<span>Leader</span>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Name"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Dept/Role"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Contact"
									/>
								</li>
								<li>
									<span>Additional Members</span>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Name"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Dept/Role"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Contact"
									/>
								</li>
								<button className="btnAddArea">+ Additional Members</button>
							</ul>
						</Panel>
						<Panel
							header="2. Define the problem"
							key="2"
							className="custom-panel"
						>
							<ul className="lstFeedback">
								<li>
									<textarea
										className="txaFeedback"
										value="01/05/2021 22:08:26 Junmin Ji (JIJ) Phone +1 409 981 5143 Apeloa para-Chlorophenylglycine Lot 202010037 off-spec on water content - 0.22% vs. BASF spec =< 0.20%. This is from preshipment COA review. On the other hand, Apeloa Spec states water content (moisture) =< 0.50% that is different from BASF's water content =< 0.20%. See attachments of COAs and Specification Report. Please note that the material# 54999463 is automatically selected by SAP based on the PO# when the YP NCM was generated - it is neither 30133646 nor 10220983 (which one?) listed in the Specification Report. The plant for the material USAD was also automatically selected by SAP based on the PO#. Jeff Groth is tentatively assigned as the investigator."
									/>
								</li>
							</ul>
						</Panel>
						<Panel
							header="3. Containment Action(s) and Interim Action(s)"
							key="3"
							className="custom-panel"
						>
							<ul className="lstFeedback">
								<li>
									<span>Containment Action(s)</span>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Containment Action(s)"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Responsible"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Date Due"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Date Compl."
									/>
								</li>
								<li>
									<span>Interim Action(s)</span>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Interim Action(s)"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Responsible"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Date Due"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Date Compl."
									/>
								</li>
							</ul>
						</Panel>
						<Panel
							header="4. Define Root Cause"
							key="4"
							className="custom-panel"
						>
							<ul className="lstFeedback">
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Define Root Cause"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="RCA Method/Tool(s) used"
									/>
								</li>
							</ul>
						</Panel>
						<Panel
							header="5. Permanent Corrective Action(s)"
							key="5"
							className="custom-panel"
						>
							<ul className="lstFeedback">
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Permanent Corrective Actions(s)"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Responsible"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Date Due"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Date Compl."
									/>
								</li>
							</ul>
						</Panel>
						<Panel
							header="6. Verification of Effectiveness"
							key="6"
							className="custom-panel"
						>
							<ul className="lstFeedback">
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Verification of Effectiveness"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Responsible"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Date Due"
									/>
								</li>
								<li>
									{/* <span>:</span> */}
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Date Compl."
									/>
								</li>
							</ul>
						</Panel>
						<Panel header="7. Prevention" key="7" className="custom-panel">
							<ul className="lstFeedback">
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Prevention"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Responsible"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Date Due"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Date Compl."
									/>
								</li>
							</ul>
						</Panel>
						<Panel
							header="8. Communicate Results and Recognize Team"
							key="8"
							className="custom-panel"
						>
							<ul className="lstFeedback">
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Communicate Results and Recognize Team"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Prepared By"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Date Compl."
									/>
								</li>
							</ul>
						</Panel>
					</Collapse>
				</div>

				<div className="boxActions">
					<button className="btnCancel">Cancel</button>
					<button className="btnPrint">Print</button>
					<button className="btnSave">Save as draft</button>
					<button className="btnSend">Submit</button>
				</div>
			</div>
		</section>
	);
}

export default DReport;
