import React, { Fragment, useEffect, useState, memo } from 'react';
import axios from '../../../axios-custom';
import store from '../../../state/store';
import { useInterval, useMemoizedFn, useReactive, useUnmount } from 'ahooks';
import { DatePicker } from 'antd';
import moment from 'moment-timezone';
import ICONradioOFF from '../../../assets/icons/toggle-inactiv.svg';
import ICONradioON from '../../../assets/icons/toggle-activ.svg';
import ICONclose from '../../../assets/icons/closeWhite.svg';
// import ICONerror from '../../../assets/icons/error.svg';
import ICONinfo from '../../../assets/icons/info-i.svg';
import { notification, Input, Modal } from 'antd';
import '../../../../node_modules/antd/lib/modal/style/index.css';
const { useModel } = store;
const { TextArea } = Input;

const SystemNotification = ({ bgHandler, isUserLogin }) => {
	// eslint-disable-next-line no-unused-vars
	const [state, dispatchers] = useModel('global');
	const { toggleLoading } = dispatchers;
	const [currenttime, setCurrenttime] = useState('');
	const [id, setid] = useState('');
	const [listData, setListData] = useState([]);
	const [type, settype] = useState('');
	const [startDateData, setStartDateData] = useState(null);
	const [endDateData, setEndDateData] = useState(null);
	const [isShowIRNen, setIsShowIRNen] = useState(false);
	const [isShowIRNde, setIsShowIRNde] = useState(false);
	const [isShowIRNfr, setIsShowIRNfr] = useState(false);
	const [isShowIRNes, setIsShowIRNes] = useState(false);
	const [isShowIRNpt, setIsShowIRNpt] = useState(false);
	const [isDst] = useState(moment().tz('Europe/Berlin').isDST());

	const [isModalVisible, setIsModalVisible] = useState(false);

	const form = useReactive({
		enmessage: '',
		demessage: '',
		frmessage: '',
		esmessage: '',
		ptmessage: '',
		fromDate: '',
		toDate: '',
		isDeactivate: 1,
	});

	useUnmount(() => {
		notification.destroy();
	});

	useInterval(() => {
		setCurrenttime(moment().tz('Europe/Berlin').format('YYYY.MM.DD HH:mm:ss'));
	}, 1000);

	useEffect(() => {
		fetchData();
		//
		async function fetchData() {
			toggleLoading();
			const response = await axios.get(
				'/feedbackServices/api/notofication-infos'
			);
			// console.log(response);
			toggleLoading();
			if (response.data && response.data.length) {
				setListData(response.data);
				//
				if (response.data[0].isDeactivate === false) {
					let localendtime = '';
					let toDate = response.data[0].toDate;
					let endIsDst = moment(toDate).tz('Europe/Berlin').isDST();
					let diff = moment(
						moment().tz('Europe/Berlin').format('YYYY-MM-DD HH:mm:ss')
					).diff(moment(moment().format('YYYY-MM-DD HH:mm:ss')), 'second');
					let isDst = moment().tz('Europe/Berlin').isDST();
					//
					if (isDst) {
						if (diff < 0) {
							if (endIsDst) {
								localendtime = moment(
									moment(toDate).subtract(diff, 'seconds')
								).format('YYYY-MM-DD HH:mm:ss');
							} else {
								localendtime = moment(
									moment(toDate).subtract(diff - 3600, 'seconds')
								).format('YYYY-MM-DD HH:mm:ss');
							}
						} else if (diff === 0) {
							localendtime = toDate;
						} else if (diff > 0) {
							if (endIsDst) {
								localendtime = moment(
									moment(toDate).add(diff, 'seconds')
								).format('YYYY-MM-DD HH:mm:ss');
							} else {
								localendtime = moment(
									moment(toDate).add(diff + 3600, 'seconds')
								).format('YYYY-MM-DD HH:mm:ss');
							}
						}
					} else {
						if (diff < 0) {
							if (endIsDst) {
								localendtime = moment(
									moment(toDate).subtract(diff + 3600, 'seconds')
								).format('YYYY-MM-DD HH:mm:ss');
							} else {
								localendtime = moment(
									moment(toDate).subtract(diff, 'seconds')
								).format('YYYY-MM-DD HH:mm:ss');
							}
						} else if (diff === 0) {
							localendtime = toDate;
						} else if (diff > 0) {
							if (endIsDst) {
								localendtime = moment(
									moment(toDate).add(diff - 3600, 'seconds')
								).format('YYYY-MM-DD HH:mm:ss');
							} else {
								localendtime = moment(
									moment(toDate).add(diff, 'seconds')
								).format('YYYY-MM-DD HH:mm:ss');
							}
						}
					}
					//
					let localendstamp = moment(localendtime).unix();
					let curentstamp = moment().unix();
					if (curentstamp > localendstamp) {
						notification.open({
							message: 'Attention',
							description: descriptionHtml(),
							icon: iconError(),
							duration: 0,
						});
					}
				}
			} else {
				setListData([]);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const iconError = () => {
		return <img className="ICONinfo" alt="Error" src={ICONinfo} />;
	};

	const descriptionHtml = () => {
		return (
			<Fragment>
				<span>
					The selected time period for the system notification is invalid.
					Please check and make the necessary changes before setting it to
					"Active".
				</span>
			</Fragment>
		);
	};

	const disabledDate = useMemoizedFn((current) => {
		return current < moment().endOf('day').subtract(1, 'day');
	});

	const changeExpandedAll = useMemoizedFn(() => {
		if (form.isDeactivate === 1) {
			form.isDeactivate = 0;
		} else {
			form.isDeactivate = 1;
		}
	});

	const add = useMemoizedFn(() => {
		settype('add');
		form.enmessage = '';
		form.demessage = '';
		form.frmessage = '';
		form.esmessage = '';
		form.ptmessage = '';
		form.fromDate = '';
		form.toDate = '';
		form.isDeactivate = 1;
		setStartDateData(null);
		setEndDateData(null);
	});

	const edit = useMemoizedFn((item) => {
		settype('edit');
		form.enmessage = item.enmessage ? item.enmessage : '';
		form.demessage = item.demessage ? item.demessage : '';
		form.frmessage = item.frmessage ? item.frmessage : '';
		form.esmessage = item.esmessage ? item.esmessage : '';
		form.ptmessage = item.ptmessage ? item.ptmessage : '';
		form.fromDate = item.fromDate;
		form.toDate = item.toDate;
		form.isDeactivate = item.isDeactivate ? 0 : 1;
		setStartDateData(moment(item.fromDate));
		setEndDateData(moment(item.toDate));
		setid(item.id);
	});

	const deleteData = useMemoizedFn(async (item) => {
		settype('');
		toggleLoading();
		await axios.delete(`/feedbackServices/api/notofication-infos/${item.id}`);
		const response = await axios.get(
			'/feedbackServices/api/notofication-infos'
		);
		// console.log(response);
		toggleLoading();
		if (response.data && response.data.length) {
			setListData(response.data);
		} else {
			setListData([]);
		}
	});

	const Save = useMemoizedFn(async () => {
		let temp = JSON.parse(JSON.stringify(form));
		let flag = false;
		if (temp.enmessage === '') {
			document.querySelector('.enmessage').classList.remove('isFilled');
			document.querySelector('.enmessage').classList.add('gotError');
			flag = true;
		}
		if (temp.enmessage.length > 112) {
			document.querySelector('.enmessage').classList.remove('isFilled');
			document.querySelector('.enmessage').classList.add('gotError');
			setIsShowIRNen(true);
			flag = true;
			return;
		}
		if (temp.demessage === '') {
			temp.demessage = temp.enmessage;
		}
		if (temp.demessage.length > 112) {
			document.querySelector('.demessage').classList.remove('isFilled');
			document.querySelector('.demessage').classList.add('gotError');
			setIsShowIRNde(true);
			flag = true;
			return;
		}
		if (temp.frmessage === '') {
			temp.frmessage = temp.enmessage;
		}
		if (temp.frmessage.length > 112) {
			document.querySelector('.frmessage').classList.remove('isFilled');
			document.querySelector('.frmessage').classList.add('gotError');
			setIsShowIRNfr(true);
			flag = true;
			return;
		}
		if (temp.esmessage === '') {
			temp.esmessage = temp.enmessage;
		}
		if (temp.esmessage.length > 112) {
			document.querySelector('.esmessage').classList.remove('isFilled');
			document.querySelector('.esmessage').classList.add('gotError');
			setIsShowIRNes(true);
			flag = true;
			return;
		}
		if (temp.ptmessage === '') {
			temp.ptmessage = temp.enmessage;
		}
		if (temp.ptmessage.length > 112) {
			document.querySelector('.ptmessage').classList.remove('isFilled');
			document.querySelector('.ptmessage').classList.add('gotError');
			setIsShowIRNpt(true);
			flag = true;
			return;
		}
		if (temp.fromDate === '') {
			document.querySelector('.fromDate').classList.add('gotError');
			flag = true;
		}
		if (temp.toDate === '') {
			document.querySelector('.toDate').classList.add('gotError');
			flag = true;
		}
		let diff = moment(moment(temp.fromDate).format('YYYY-MM-DD HH:mm:ss')).diff(
			moment(moment(temp.toDate).format('YYYY-MM-DD HH:mm:ss')),
			'second'
		);
		if (diff >= 0) {
			document.querySelector('.toDate').classList.add('gotError');
			setIsModalVisible(true);
			flag = true;
		}
		if (flag) {
			return;
		}
		toggleLoading();
		if (type === 'add') {
			let obj = JSON.parse(JSON.stringify(form));
			obj.isDeactivate = obj.isDeactivate === 1 ? false : true;
			// console.log(obj);
			await axios.post('/feedbackServices/api/notofication-infos', obj);
		} else {
			let obj = JSON.parse(JSON.stringify(form));
			obj.isDeactivate = obj.isDeactivate === 1 ? false : true;
			obj.id = id;
			// console.log(obj);
			await axios.post('/feedbackServices/api/notofication-infos', obj);
		}
		const response = await axios.get(
			'/feedbackServices/api/notofication-infos'
		);
		// console.log(response);
		toggleLoading();
		if (response.data && response.data.length) {
			setListData(response.data);
		} else {
			setListData([]);
		}
		settype('');
	});

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const Cancel = useMemoizedFn(() => {
		settype('');
	});

	const inputChangeHandler = useMemoizedFn((e, val) => {
		if (val === 'enmessage') {
			setIsShowIRNen(false);
			e.target.classList.remove('gotError');
			form.enmessage = e.target.value;
			//
			if (e.target.value !== '') {
				e.target.classList.add('isFilled');
			} else {
				e.target.classList.remove('isFilled');
			}
			//
			if (e.target.value.length > 112) {
				document.querySelector('.enmessage').classList.remove('isFilled');
				document.querySelector('.enmessage').classList.add('gotError');
				setIsShowIRNen(true);
			}
		} else if (val === 'demessage') {
			setIsShowIRNde(false);
			e.target.classList.remove('gotError');
			form.demessage = e.target.value;
			//
			if (e.target.value !== '') {
				e.target.classList.add('isFilled');
			} else {
				e.target.classList.remove('isFilled');
			}
			//
			if (e.target.value.length > 112) {
				document.querySelector('.demessage').classList.remove('isFilled');
				document.querySelector('.demessage').classList.add('gotError');
				setIsShowIRNde(true);
			}
		} else if (val === 'frmessage') {
			setIsShowIRNfr(false);
			e.target.classList.remove('gotError');
			form.frmessage = e.target.value;
			//
			if (e.target.value !== '') {
				e.target.classList.add('isFilled');
			} else {
				e.target.classList.remove('isFilled');
			}
			//
			if (e.target.value.length > 112) {
				document.querySelector('.frmessage').classList.remove('isFilled');
				document.querySelector('.frmessage').classList.add('gotError');
				setIsShowIRNfr(true);
			}
		} else if (val === 'esmessage') {
			setIsShowIRNes(false);
			e.target.classList.remove('gotError');
			form.esmessage = e.target.value;
			//
			if (e.target.value !== '') {
				e.target.classList.add('isFilled');
			} else {
				e.target.classList.remove('isFilled');
			}
			//
			if (e.target.value.length > 112) {
				document.querySelector('.esmessage').classList.remove('isFilled');
				document.querySelector('.esmessage').classList.add('gotError');
				setIsShowIRNes(true);
			}
		} else if (val === 'ptmessage') {
			setIsShowIRNpt(false);
			e.target.classList.remove('gotError');
			form.ptmessage = e.target.value;
			//
			if (e.target.value !== '') {
				e.target.classList.add('isFilled');
			} else {
				e.target.classList.remove('isFilled');
			}
			//
			if (e.target.value.length > 112) {
				document.querySelector('.ptmessage').classList.remove('isFilled');
				document.querySelector('.ptmessage').classList.add('gotError');
				setIsShowIRNpt(true);
			}
		}
	});

	const datePickerOnChange = useMemoizedFn((key, date) => {
		form[key] = moment(date).format('YYYY-MM-DD HH:mm:00');
		if (key === 'fromDate') {
			document.querySelector('.fromDate').classList.remove('gotError');
			setStartDateData(date);
		} else {
			document.querySelector('.toDate').classList.remove('gotError');
			setEndDateData(date);
		}
	});

	return (
		<section className="systemnotificationwrap">
			<Modal
				open={isModalVisible}
				className="errorModal"
				footer={null}
				closable={false}
				getContainer={false}
			>
				<div className="contentinto">
					<div className="contentwrap">
						Please ensure that the selected "To" date and time is after the
						"From" date and time before saving changes again.
					</div>
					<div className="buttongroup">
						<button className="btnCancel" onClick={handleCancel}>
							Closed
						</button>
						{/* <button className="btnLogin" onClick={handleCancel}>
							{t('Detail.Closed')}
						</button> */}
					</div>
				</div>
				<div className="closeicon" onClick={handleCancel}>
					<img alt="close" src={ICONclose} />
				</div>
			</Modal>
			{listData.length < 1 && type === '' ? (
				<div className="maintitle">
					<span className="button">
						<button className="btnSend" onClick={() => add()}>
							Add
						</button>
					</span>
				</div>
			) : (
				<Fragment />
			)}

			{listData.map((item, index) => {
				return (
					<Fragment key={index}>
						<div className="maintitle">
							<span className="titlewrap">
								<span className="title">{item.enmessage}</span>
								<span className="titledate">
									{item.fromDate} - {item.toDate}
								</span>
							</span>
							<span className="button">
								<button className="btnSend" onClick={() => edit(item)}>
									Edit
								</button>
								<button className="btnDelete" onClick={() => deleteData(item)}>
									Delete
								</button>
							</span>
						</div>
					</Fragment>
				);
			})}
			{type === 'add' || type === 'edit' ? (
				<Fragment>
					<div className="inputwrap">
						<TextArea
							className="txaFeedback messageinput enmessage"
							value={form.enmessage}
							onChange={(e) => inputChangeHandler(e, 'enmessage')}
							onBlur={() => setIsShowIRNen(false)}
							autoSize
						/>
						<span className="editbutton">English</span>
						<span className={isShowIRNen ? 'errInput visible' : 'errInput'}>
							System Notification should not be more than 112 characters.
						</span>
					</div>
					<div className="inputwrap">
						<TextArea
							className="txaFeedback messageinput demessage"
							value={form.demessage}
							onChange={(e) => inputChangeHandler(e, 'demessage')}
							onBlur={() => setIsShowIRNde(false)}
							autoSize
						/>
						<span className="editbutton">Deutsch</span>
						<span className={isShowIRNde ? 'errInput visible' : 'errInput'}>
							System Notification should not be more than 112 characters.
						</span>
					</div>
					<div className="inputwrap">
						<TextArea
							className="txaFeedback messageinput frmessage"
							value={form.frmessage}
							onChange={(e) => inputChangeHandler(e, 'frmessage')}
							onBlur={() => setIsShowIRNfr(false)}
							autoSize
						/>
						<span className="editbutton">Français</span>
						<span className={isShowIRNfr ? 'errInput visible' : 'errInput'}>
							System Notification should not be more than 112 characters.
						</span>
					</div>
					<div className="inputwrap">
						<TextArea
							className="txaFeedback messageinput esmessage"
							value={form.esmessage}
							onChange={(e) => inputChangeHandler(e, 'esmessage')}
							onBlur={() => setIsShowIRNes(false)}
							autoSize
						/>
						<span className="editbutton">Español</span>
						<span className={isShowIRNes ? 'errInput visible' : 'errInput'}>
							System Notification should not be more than 112 characters.
						</span>
					</div>
					<div className="inputwrap">
						<TextArea
							className="txaFeedback messageinput ptmessage"
							value={form.ptmessage}
							onChange={(e) => inputChangeHandler(e, 'ptmessage')}
							onBlur={() => setIsShowIRNpt(false)}
							autoSize
						/>
						<span className="editbutton">Português</span>
						<span className={isShowIRNpt ? 'errInput visible' : 'errInput'}>
							System Notification should not be more than 112 characters.
						</span>
					</div>
					{/*  */}
					<div className="deactivewrap">
						<span className="periodtitle">
							Period of display
							<span className="periodcontent">
								{isDst ? (
									<Fragment>CET Time: {currenttime}</Fragment>
								) : (
									<Fragment>CEST Time: {currenttime}</Fragment>
								)}
							</span>
						</span>
						<span className="deactivewrapa">
							{form.isDeactivate === 1 ? (
								<span className="deactivewrapatitle">Active</span>
							) : (
								<span className="deactivewrapatitle">Deactivate</span>
							)}
							<button
								className="btnAllExpand"
								onClick={() => changeExpandedAll()}
							>
								{form.isDeactivate === 1 ? (
									<img alt="Shrinked" src={ICONradioON} />
								) : (
									<img alt="Expanded" src={ICONradioOFF} />
								)}
							</button>
						</span>
					</div>
					{/* <div className="period">
						Period of display
						<span className="cettime">CET/CEST Time: {currenttime}</span>
					</div> */}
					<div className="buttonfunction">
						<span className="datepickwrap">
							<span className="title">From:</span>
							<DatePicker
								onChange={(date) => datePickerOnChange('fromDate', date)}
								disabledDate={disabledDate}
								suffixIcon={<Fragment />}
								allowClear={false}
								className="dtpFeedback fromDate"
								format="YYYY-MM-DD HH:mm"
								showToday={false}
								showTime
								inputReadOnly
								value={startDateData}
							/>
							<span className="title ml10">To:</span>
							<DatePicker
								onChange={(date) => datePickerOnChange('toDate', date)}
								disabledDate={disabledDate}
								suffixIcon={<Fragment />}
								allowClear={false}
								className="dtpFeedback toDate"
								format="YYYY-MM-DD HH:mm"
								showToday={false}
								showTime
								inputReadOnly
								value={endDateData}
							/>
						</span>
						<span className="button">
							<button className="btnCancel" onClick={() => Cancel()}>
								Cancel
							</button>
							<button className="btnSend" onClick={() => Save()}>
								Save
							</button>
						</span>
					</div>
				</Fragment>
			) : (
				<Fragment />
			)}
		</section>
	);
};

export default memo(SystemNotification);
