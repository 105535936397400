import React, { useImperativeHandle, memo, useState, useRef } from 'react';
// import { useTranslation } from 'react-i18next';
import { Carousel, Modal, Checkbox } from 'antd';
import '../../../../node_modules/antd/lib/carousel/style/index.css';
import '../../../../node_modules/antd/lib/modal/style/index.css';
import '../../../../node_modules/antd/lib/checkbox/style/index.css';
import { LeftOutlined, RightOutlined, CloseOutlined } from '@ant-design/icons';
import slide1 from '../../../assets/images/slide1.png';
import slide2 from '../../../assets/images/slide2.png';
import slide3 from '../../../assets/images/slide3.png';
import slide4 from '../../../assets/images/slide4.png';
import { useMemoizedFn } from 'ahooks';

const SliderPopup = React.forwardRef((props, ref) => {
	// const { t } = useTranslation();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [autoplay, setAutoplay] = useState(true);
	const SliderRef = useRef();

	useImperativeHandle(ref, () => ({
		showModel: () => {
			if (
				!localStorage.getItem('NCM_landing_popup_nevershow') ||
				localStorage.getItem('NCM_landing_popup_nevershow') !== 'true'
			) {
				if (
					!localStorage.getItem('NCM_landing_popup') ||
					localStorage.getItem('NCM_landing_popup') !== 'true'
				) {
					setIsModalVisible(true);
					localStorage.setItem('NCM_landing_popup', true);
				}
			}
		},
	}));

	const handleCancel = useMemoizedFn(() => {
		setIsModalVisible(false);
	});

	const onChange = useMemoizedFn((e) => {
		if (e.target.checked === true) {
			localStorage.setItem('NCM_landing_popup_nevershow', true);
		}
	});

	const carouselleft = () => {
		SliderRef.current.prev();
		setAutoplay(false);
	};

	const carouselright = () => {
		SliderRef.current.next();
		setAutoplay(false);
	};

	return (
		<Modal
			open={isModalVisible}
			className="carouselmodel"
			footer={null}
			closable={false}
			getContainer={false}
		>
			<div className="contentinto">
				<Carousel ref={SliderRef} autoplaySpeed={10000} autoplay={autoplay}>
					<div className="slidewrap">
						<img src={slide1} alt="" />
					</div>
					<div className="slidewrap">
						<img src={slide2} alt="" />
					</div>
					<div className="slidewrap">
						<img src={slide3} alt="" />
					</div>
					<div className="slidewrap">
						<img src={slide4} alt="" />
					</div>
				</Carousel>
				<LeftOutlined
					onClick={() => carouselleft()}
					className="carouselleft"
					style={{ fontSize: '16px', color: '#08c' }}
				/>
				<RightOutlined
					onClick={() => carouselright()}
					className="carouselright"
					style={{ fontSize: '16px', color: '#08c' }}
				/>
			</div>
			<div className="checkboxwrap">
				<Checkbox onChange={onChange}>Don't show again</Checkbox>
			</div>
			<div className="closeicon" onClick={handleCancel}>
				<CloseOutlined />
			</div>
		</Modal>
	);
});

export default memo(SliderPopup);
