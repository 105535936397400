import React, { useImperativeHandle, memo, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ICONedit from '../../../assets/icons/edit.svg';
import ICONclose from '../../../assets/icons/close.svg';
import { useResponsive, useMemoizedFn } from 'ahooks';
import store from '../../../state/store';
import axios from '../../../axios-custom';
import { Tooltip } from 'antd';
import '../../../../node_modules/antd/lib/tooltip/style/index.css';
const { useModel } = store;

const FlyoutDeputyList = React.forwardRef((props, ref) => {
	const { t } = useTranslation();
	const responsive = useResponsive();
	const [isDeleteShow, setIsDeleteShow] = useState(false);
	const [delObj, setDelObj] = useState({});
	// eslint-disable-next-line no-unused-vars
	const [tempState, dispatchers] = useModel('global');
	const { toggleLoading, toastMessageShow } = dispatchers;

	useImperativeHandle(ref, () => ({
		//
	}));

	const titleCase = useMemoizedFn((str) => {
		if (str === '') {
			return '';
		} else {
			let arr = str.toLowerCase().split(' ');
			for (let i = 0; i < arr.length; i++) {
				if (arr[i] !== '') {
					arr[i] = arr[i][0].toUpperCase() + arr[i].substring(1, arr[i].length);
				} else {
					continue;
				}
			}
			return arr.join(' ');
		}
	});

	const list = useMemoizedFn(() => {
		if (!responsive.small) {
			return (
				<ul className="lstDeputiesmini">
					{props.deputyData.map((item, index) => {
						return (
							<li key={index}>
								<div className="valuewrap">
									<span className="name">{t('Deputy.Position')}</span>
									<span className="value">{index + 1}</span>
									<span className="name">{t('Deputy.Firstname')}</span>
									<span className="value">{titleCase(item.fname)}</span>
									<span className="name">{t('Deputy.Lastname')}</span>
									<span className="value">{titleCase(item.lname)}</span>
									<span className="name">{t('Deputy.User')}</span>
									<span className="value">{item.deputy.toLowerCase()}</span>
									<span className="name">{t('Deputy.Period')}</span>
									<span className="value">
										{item.fromdate} - {item.todate}
									</span>
								</div>
								<div className="boxInteract">
									<button
										className="btnChangeDeputy"
										onClick={() => editDeputyShow(item)}
									>
										<img alt="edit" src={ICONedit} />
									</button>
									<button
										className="btnChangeDeputy"
										onClick={() => deleteDeputyShow(item)}
									>
										<img alt="delete" src={ICONclose} />
									</button>
								</div>
							</li>
						);
					})}
				</ul>
			);
		} else if (!responsive.middle) {
			return (
				<ul className="lstDeputiesmall">
					{props.deputyData.map((item, index) => {
						return (
							<li key={index}>
								<div className="valuewrap">
									<span className="name">{t('Deputy.Position')}</span>
									<span className="value">{index + 1}</span>
									<span className="name">{t('Deputy.Firstname')}</span>
									<span className="value">{titleCase(item.fname)}</span>
									<span className="name">{t('Deputy.Lastname')}</span>
									<span className="value">{titleCase(item.lname)}</span>
									<span className="name">{t('Deputy.User')}</span>
									<span className="value">{item.deputy.toLowerCase()}</span>
									<span className="name">{t('Deputy.Period')}</span>
									<span className="value">
										{item.fromdate} - {item.todate}
									</span>
								</div>
								<div className="boxInteract">
									<button
										className="btnChangeDeputy"
										onClick={() => editDeputyShow(item)}
									>
										<img alt="edit" src={ICONedit} />
									</button>
									<button
										className="btnChangeDeputy"
										onClick={() => deleteDeputyShow(item)}
									>
										<img alt="delete" src={ICONclose} />
									</button>
								</div>
							</li>
						);
					})}
				</ul>
			);
		} else if (!responsive.huge) {
			return (
				<ul className="lstDeputiemiddle">
					<li className="title">
						<span className="item1">Pos.</span>
						<span className="item2">
							<span>{t('Deputy.Firstname')}</span>
							<span>{t('Deputy.Lastname')}</span>
						</span>
						<span className="item3">
							<span>{t('Deputy.User')}</span>
							<span>{t('Deputy.Period')}</span>
						</span>
						<span className="item4">&nbsp;</span>
					</li>
					{props.deputyData.map((item, index) => {
						return (
							<li className="content" key={index}>
								<span className="item1">{index + 1}</span>
								<span className="item2">
									<Tooltip
										placement="topLeft"
										title={titleCase(item.lname)}
										color="#1fa1d2"
										overlayClassName="deputytooltip"
									>
										<span className="value">{titleCase(item.fname)}</span>
									</Tooltip>
									<Tooltip
										placement="topLeft"
										title={titleCase(item.lname)}
										color="#1fa1d2"
										overlayClassName="deputytooltip"
									>
										<span className="value">{titleCase(item.lname)}</span>
									</Tooltip>
								</span>
								<span className="item3">
									<Tooltip
										placement="topLeft"
										title={item.deputy.toLowerCase()}
										color="#1fa1d2"
										overlayClassName="deputytooltip"
									>
										<span className="value">{item.deputy.toLowerCase()}</span>
									</Tooltip>
									<span className="value">
										{item.fromdate} - {item.todate}
									</span>
								</span>
								<span className="item4">
									<button
										className="btnChangeDeputy"
										onClick={() => editDeputyShow(item)}
									>
										<img alt="edit" src={ICONedit} />
									</button>
									<button
										className="btnChangeDeputy"
										onClick={() => deleteDeputyShow(item)}
									>
										<img alt="delete" src={ICONclose} />
									</button>
								</span>
							</li>
						);
					})}
				</ul>
			);
		} else if (responsive.huge) {
			return (
				<ul className="lstDeputielarge">
					<li className="title">
						<span className="item1">Pos.</span>
						<span className="item2">{t('Deputy.Firstname')}</span>
						<span className="item3">{t('Deputy.Lastname')}</span>
						<span className="item4">{t('Deputy.User')}</span>
						<span className="item5">{t('Deputy.Period')}</span>
						<span className="item6">&nbsp;</span>
					</li>
					{props.deputyData.map((item, index) => {
						return (
							<li className="content" key={index}>
								<span className="item1">{index + 1}</span>
								<Tooltip
									placement="topLeft"
									title={titleCase(item.fname)}
									color="#1fa1d2"
									overlayClassName="deputytooltip"
								>
									<span className="item2">{titleCase(item.fname)}</span>
								</Tooltip>
								<Tooltip
									placement="topLeft"
									title={titleCase(item.lname)}
									color="#1fa1d2"
									overlayClassName="deputytooltip"
								>
									<span className="item3">{titleCase(item.lname)}</span>
								</Tooltip>
								<Tooltip
									placement="topLeft"
									title={item.deputy.toLowerCase()}
									color="#1fa1d2"
									overlayClassName="deputytooltip"
								>
									<span className="item4">{item.deputy.toLowerCase()}</span>
								</Tooltip>
								<span className="item5">
									{item.fromdate} - {item.todate}
								</span>
								<span className="item6">
									<button
										className="btnChangeDeputy"
										onClick={() => editDeputyShow(item)}
									>
										<img alt="edit" src={ICONedit} />
									</button>
									<button
										className="btnChangeDeputy"
										onClick={() => deleteDeputyShow(item)}
									>
										<img alt="delete" src={ICONclose} />
									</button>
								</span>
							</li>
						);
					})}
				</ul>
			);
		}
	});

	const handleOk = async () => {
		// console.log(delObj);
		let loggedinId = '';
		let userBak = JSON.parse(localStorage.getItem('NCM_User_bak'));
		let user = JSON.parse(localStorage.getItem('NCM_User'));
		if (userBak && JSON.stringify(userBak) !== '{}') {
			loggedinId = userBak.email;
		} else {
			loggedinId = user.email;
		}
		let postobj = {
			imdeputy: delObj.deputy,
			imfromdate: delObj.fromdate,
			immainpartner: delObj.mainpartner,
			imop: 'D',
			imtodate: delObj.todate,
			fname: delObj.fname,
			lname: delObj.lname,
			loggedinId: loggedinId,
		};
		toggleLoading();
		const response = await axios.post(
			'/feedbackServices/portalSap/maintainDeputy',
			postobj
		);
		toggleLoading();
		// console.log(response);
		if (response.data.exsubrc === 0) {
			let notificationObj = {
				type: 'success',
				title: t('Detail.submitSuccess1'),
				description: `${t(
					'Deputy.deletesuccess1'
				)} ${postobj.imdeputy.toLowerCase()} ${t('Deputy.deletesuccess2')}`,
				duration: 5,
			};
			toastMessageShow(notificationObj);
			props.handleDeputy('', {});
			props.fetchDeputy();
			setIsDeleteShow(false);
		} else {
			// exmessage
			let notificationObj = {
				type: 'error',
				title: 'Error',
				description: response.data.exmessage,
				duration: 0,
			};
			toastMessageShow(notificationObj);
		}
	};

	const deleteDeputyShow = (item) => {
		props.handleDeputy('', {});
		setIsDeleteShow(true);
		setDelObj(item);
	};

	const handleCancel = () => {
		setIsDeleteShow(false);
	};

	const addDeputyShow = () => {
		setIsDeleteShow(false);
		props.handleDeputy('add', {});
		setDelObj({});
	};

	const editDeputyShow = (item) => {
		let obj = JSON.parse(JSON.stringify(item));
		setIsDeleteShow(false);
		props.handleDeputy('edit', obj);
		setDelObj({});
	};

	return (
		<Fragment>
			<h3>{t('Header.DeputyManagement')}</h3>

			<p className="pageInfo">{t('Deputy.DeputyInfo')}</p>

			{props.deputyData.length ? list() : <Fragment />}

			{props.deputyData.length < 15 ? (
				<div className="boxAddNew">
					<button
						className={
							props.deputyAction !== '' ? 'btnAddDeputy active' : 'btnAddDeputy'
						}
						onClick={() => addDeputyShow()}
					>
						+ {t('Deputy.AddDeputy')}
					</button>
				</div>
			) : (
				<div className="depety10">{t('Deputy.Deputy10')}</div>
			)}

			{isDeleteShow ? (
				<>
					<h4>{t('Deputy.DeleteDeputy1')}</h4>
					<p className="pageInfo">{t('Deputy.DeleteDeputy2')}</p>
					<div className="boxActions">
						<button className="btnCancel" onClick={() => handleCancel()}>
							{t('Detail.Cancel')}
						</button>
						<button className="btnApply" onClick={() => handleOk()}>
							{t('Deputy.yes')}
						</button>
					</div>
				</>
			) : (
				<Fragment />
			)}
		</Fragment>
	);
});

export default memo(FlyoutDeputyList);
