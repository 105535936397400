import React, { useState, useEffect } from 'react';
import './index.scss';
import LeftDetail from './detail';
import IMAGlandingBig from '../../assets/images/landing_big.jpg';
import ICONdown from '../../assets/icons/arrow-down.svg';
import ICONup from '../../assets/icons/arrow-up.svg';
import { Input, Modal, Collapse } from 'antd';
import '../../../node_modules/antd/lib/input/style/index.css';
import '../../../node_modules/antd/lib/button/style/index.css';
import '../../../node_modules/antd/lib/modal/style/index.css';
import '../../../node_modules/antd/lib/collapse/style/index.css';
import { getQueryVariable } from '../../utils/util';
import { useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useMount } from 'ahooks';

const { Panel } = Collapse;

function DReport({ bgHandler }) {
	const [isModalVisible, setIsModalVisible] = useState(true);
	const [expandedSupplier, setExpandedSupplier] = useState(false);

	const location = useLocation();

	useEffect(() => {
		bgHandler(false, false, IMAGlandingBig, false);
	}, [bgHandler]);

	useMount(() => {
		const { search } = location;
		if (search) {
			let code = getQueryVariable(search, 'code');
			const myHeaders = new Headers();
			myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
			const urlencoded = new URLSearchParams();
			//
			let redirectUrl = '';
			let client_id = '';
			let client_secret = '';
			let federation = '';
			if (window.location.origin.includes('localhost')) {
				redirectUrl = 'https://localhost:8100';
				client_id = '355006f9-603c-4642-a38a-6460e0737b57';
				client_secret =
					'oAILlxjLNHyplhvYY9G7RojuEAVmkHpgXYvBEUjKFJSJyjJWace7BFfaxjtk2udUDqN7Qrx8aNW-N0_h51HgQg';
				federation = 'https://federation-qa.basf.com/nidp/oauth/nam/token';
			} else if (window.location.origin.includes('dev')) {
				redirectUrl = 'https://dev.ncm.basf.com';
				client_id = 'a9526525-7323-41db-8a3f-955090557519';
				client_secret =
					'oAILlxjLNHyplhvYY9G7RojuEAVmkHpgXYvBEUjKFJSJyjJWace7BFfaxjtk2udUDqN7Qrx8aNW-N0_h51HgQg';
				federation = 'https://federation.basf.com/nidp/oauth/nam/token';
			} else if (window.location.origin.includes('qual')) {
				redirectUrl = 'https://qual.ncm.basf.com';
				client_id = '355006f9-603c-4642-a38a-6460e0737b57';
				client_secret =
					'YV_a1PcT95SipTKKkzJj2f-k8Bu7KQNRIbwww3lOs8gQ96wcRVOxSHQM128L3kp4t8UMQwKofsBwZq0oPK7W_Q';
				federation = 'https://federation-qa.basf.com/nidp/oauth/nam/token';
			} else {
				redirectUrl = 'https://ncm.basf.com';
				client_id = 'a9526525-7323-41db-8a3f-955090557519';
				client_secret =
					'oAILlxjLNHyplhvYY9G7RojuEAVmkHpgXYvBEUjKFJSJyjJWace7BFfaxjtk2udUDqN7Qrx8aNW-N0_h51HgQg';
				federation = 'https://federation.basf.com/nidp/oauth/nam/token';
			}
			//
			urlencoded.append('client_id', client_id);
			urlencoded.append('client_secret', client_secret);
			urlencoded.append('code', code);
			urlencoded.append('grant_type', 'authorization_code');
			urlencoded.append('resourceServer', 'Unencrypted');
			urlencoded.append('redirect_uri', redirectUrl);

			const requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: urlencoded,
				redirect: 'follow',
			};
			checkUser(federation, requestOptions);
		}
	});

	const checkUser = async (federation, requestOptions) => {
		const temp = await fetch(federation, requestOptions);
		const userTemp = await temp.json();
		console.log(userTemp);
		if (temp.status === 400) {
			console.log(userTemp.error_description);
			// Federation Service Error
			return;
		}
		//
		let decode = {};
		try {
			decode = jwtDecode(userTemp.access_token);
		} catch (error) {
			//
			return;
		}
		console.log(decode);
		if (decode.mail === 'jonathan.poh@basf.com') {
			setIsModalVisible(false);
		}
		return;
	};

	const expandIconRender = (isActive) => {
		if (isActive) {
			return <img className="accordion-icon expand" alt="" src={ICONdown} />;
		} else {
			return <img className="accordion-icon shrink" alt="" src={ICONup} />;
		}
	};

	const goFed = () => {
		localStorage.setItem('NCM_Announcement', 'true');
		let redirectUrl = '';
		let client_id = '';
		let federation = '';
		if (window.location.origin.includes('localhost')) {
			redirectUrl = 'https://qual.ncm.basf.com';
			client_id = '355006f9-603c-4642-a38a-6460e0737b57';
			federation = 'https://federation-qa.basf.com/nidp/oauth/nam/authz';
		} else if (window.location.origin.includes('dev')) {
			redirectUrl = 'https://dev.ncm.basf.com';
			client_id = 'a9526525-7323-41db-8a3f-955090557519';
			federation = 'https://federation.basf.com/nidp/oauth/nam/authz';
		} else if (window.location.origin.includes('qual')) {
			redirectUrl = 'https://qual.ncm.basf.com';
			client_id = '355006f9-603c-4642-a38a-6460e0737b57';
			federation = 'https://federation-qa.basf.com/nidp/oauth/nam/authz';
		} else {
			redirectUrl = 'https://ncm.basf.com';
			client_id = 'a9526525-7323-41db-8a3f-955090557519';
			federation = 'https://federation.basf.com/nidp/oauth/nam/authz';
		}
		const fedPath = `${federation}?client_id=${client_id}&scope=UserProfileService&response_type=code&redirect_uri=${encodeURIComponent(
			redirectUrl
		)}`;
		// const fedPath = `https://federation-qa.basf.com/nidp/oauth/nam/authz?client_id=8d3c6891-d0a7-4e5d-a720-a03e224fa6d3&scope=UserProfileService&response_type=code&redirect_uri=https://dev.sncm.basf.com`;
		document.location.href = fedPath;
	};

	const onSearch = (value) => {
		// goFed();
		if (value === '8dreport') {
			setIsModalVisible(false);
		}
	};

	return (
		<section className="dreport print_hide">
			<Modal
				style={{
					maxWidth: '100vw',
					top: 0,
					paddingBottom: 0,
				}}
				className="passmodal"
				bodyStyle={{
					height: 'calc(100vh - 48px)',
				}}
				width="100vw"
				open={isModalVisible}
				footer={null}
				closable={false}
			>
				<div
					className="passwrap"
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignContent: 'center',
					}}
				>
					<div
						className="passwordwrap"
						style={{ width: '400px', marginTop: '20px' }}
					>
						<Input.Search
							placeholder="input password"
							allowClear
							enterButton="Submit"
							size="large"
							type="password"
							onSearch={onSearch}
						/>
					</div>
				</div>
			</Modal>
			<div className="supplier">
				<div className="boxHeadSupplier">
					<span>Date</span>
					<h3>09-08-2021</h3>
					<button
						className={
							expandedSupplier
								? 'btnToggleSupplier expanded'
								: 'btnToggleSupplier'
						}
						onClick={() => setExpandedSupplier(!expandedSupplier)}
					>
						<img className="expand" alt="Expand" src={ICONdown} />
						<img className="shrink" alt="Shrink" src={ICONup} />
						<span className="lessInfo">show less details</span>
						<span className="moreInfo">show more details</span>
					</button>
				</div>

				<LeftDetail expandedSupplier={expandedSupplier}></LeftDetail>
			</div>
			<div className="feedback">
				<span className="status open">
					<span>Open</span>
				</span>

				{/* <div className="boxHeadFeedback">
					<span>Createdon 123</span>
					<h3>NC 123</h3>
				</div>

				<h2>123</h2>
				<p className="description">123</p> */}

				<div>
					<h5>Your Material No.</h5>
					<ul className="lstFeedback">
						<li>
							<input
								type="text"
								id="txb_description"
								className="txbFeedback"
								value=""
								placeholder="Your Material No."
							/>
						</li>
					</ul>
					{/* 1 */}
					<Collapse
						bordered={false}
						defaultActiveKey={['1']}
						className="custom-collapse"
						expandIconPosition="end"
						ghost
						expandIcon={({ isActive }) => expandIconRender(isActive)}
					>
						<Panel header="1. Team" key="1" className="custom-panel">
							<ul className="lstFeedback">
								<li>
									<span>Leader</span>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Name"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Dept/Role"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Contact"
									/>
								</li>
								<li>
									<span>Additional Members</span>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Name"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Dept/Role"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Contact"
									/>
								</li>
								<button className="btnAddArea">+ Additional Members</button>
							</ul>
						</Panel>
						<Panel
							header="2. Define the problem"
							key="2"
							className="custom-panel"
						>
							<ul className="lstFeedback">
								<li>
									<textarea
										className="txaFeedback"
										value="01/05/2021 22:08:26 Junmin Ji (JIJ) Phone +1 409 981 5143 Apeloa para-Chlorophenylglycine Lot 202010037 off-spec on water content - 0.22% vs. BASF spec =< 0.20%. This is from preshipment COA review. On the other hand, Apeloa Spec states water content (moisture) =< 0.50% that is different from BASF's water content =< 0.20%. See attachments of COAs and Specification Report. Please note that the material# 54999463 is automatically selected by SAP based on the PO# when the YP NCM was generated - it is neither 30133646 nor 10220983 (which one?) listed in the Specification Report. The plant for the material USAD was also automatically selected by SAP based on the PO#. Jeff Groth is tentatively assigned as the investigator."
									/>
								</li>
							</ul>
						</Panel>
						<Panel
							header="3. Containment Action(s) and Interim Action(s)"
							key="3"
							className="custom-panel"
						>
							<ul className="lstFeedback">
								<li>
									<span>Containment Action(s)</span>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Containment Action(s)"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Responsible"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Date Due"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Date Compl."
									/>
								</li>
								<li>
									<span>Interim Action(s)</span>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Interim Action(s)"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Responsible"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Date Due"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Date Compl."
									/>
								</li>
							</ul>
						</Panel>
						<Panel
							header="4. Define Root Cause"
							key="4"
							className="custom-panel"
						>
							<ul className="lstFeedback">
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Define Root Cause"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="RCA Method/Tool(s) used"
									/>
								</li>
							</ul>
						</Panel>
						<Panel
							header="5. Permanent Corrective Action(s)"
							key="5"
							className="custom-panel"
						>
							<ul className="lstFeedback">
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Permanent Corrective Actions(s)"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Responsible"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Date Due"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Date Compl."
									/>
								</li>
							</ul>
						</Panel>
						<Panel
							header="6. Verification of Effectiveness"
							key="6"
							className="custom-panel"
						>
							<ul className="lstFeedback">
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Verification of Effectiveness"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Responsible"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Date Due"
									/>
								</li>
								<li>
									{/* <span>:</span> */}
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Date Compl."
									/>
								</li>
							</ul>
						</Panel>
						<Panel header="7. Prevention" key="7" className="custom-panel">
							<ul className="lstFeedback">
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Prevention"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Responsible"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Date Due"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Date Compl."
									/>
								</li>
							</ul>
						</Panel>
						<Panel
							header="8. Communicate Results and Recognize Team"
							key="8"
							className="custom-panel"
						>
							<ul className="lstFeedback">
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Communicate Results and Recognize Team"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Prepared By"
									/>
								</li>
								<li>
									<input
										type="text"
										id="txb_description"
										className="txbFeedback"
										value=""
										placeholder="Date Compl."
									/>
								</li>
							</ul>
						</Panel>
					</Collapse>
				</div>

				<div className="boxActions">
					<button className="btnCancel">Cancel</button>
					<button className="btnPrint">Print</button>
					<button className="btnSave">Save as draft</button>
					<button className="btnSend">Submit</button>
				</div>
			</div>
		</section>
	);
}

export default DReport;
