import React, { useState, useEffect, Fragment, useRef } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import ICONlogo from '../../assets/icons/logo.svg';
import ICONmenu from '../../assets/icons/menu.svg';
import ICONclose from '../../assets/icons/close.svg';
import ICONuser from '../../assets/icons/user.svg';
import ICONdeputyman from '../../assets/icons/deputy-manage.svg';
import ICONlogout from '../../assets/icons/logout.svg';
import ICONlogoutGrey from '../../assets/icons/logoutgrey.svg';
import ICONAccessibility from '../../assets/icons/accessibility.svg';
import ICONinfo from '../../assets/icons/info-i.svg';
import ICONlanguages from '../../assets/icons/languages.svg';
import { useTranslation } from 'react-i18next';
import { useMount, configResponsive, useResponsive, useSize } from 'ahooks';
import './Header.scss';
import axios from '../../axios-custom';
import { awaitWrap } from '../../utils/util';
import store from '../../state/store';
import useWatch from '../../hook/useWatch';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/pt';
import 'moment/locale/es';
import localeEN from 'antd/es/date-picker/locale/en_US';
import localeFR from 'antd/es/date-picker/locale/fr_FR';
import localeDE from 'antd/es/date-picker/locale/de_DE';
import localePT from 'antd/es/date-picker/locale/pt_PT';
import localeES from 'antd/es/date-picker/locale/es_ES';
import FlyoutLanguage from './components/FlyoutLanguage';
import FlyoutProfile from './components/FlyoutProfile';
import FlyoutDeputyList from './components/FlyoutDeputyList';
import FlyoutDeputyEdit from './components/FlyoutDeputyEdit';
import FlyoutDeputyAdd from './components/FlyoutDeputyAdd';
import { notification, Tooltip } from 'antd';
import '../../../node_modules/antd/lib/tooltip/style/index.css';
const { useModel } = store;

configResponsive({
	mini: 1,
	small: 480,
	middle: 768,
	large: 1023,
	huge: 1280,
});

function useQuery() {
	const searchLine = useLocation().search;
	const authCode = new URLSearchParams(searchLine).get('code');
	return authCode;
}

const Header = ({ doLogin, doLoading }) => {
	const [state, dispatchers] = useModel('global');
	const { loading, detailIsPrompt, showDeputy } = state;
	const {
		toggleLoading,
		changeLanguagePopup,
		changeUserProfileDetail,
		changeShowDeputy,
	} = dispatchers;
	const location = useLocation();
	const [locale, setLocale] = useState(localeEN);
	const { t, i18n } = useTranslation();
	// eslint-disable-next-line no-unused-vars
	const [urlLink, setUrllink] = useState('/');
	//
	const flyoutDeputyAddRef = useRef(null);
	const flyoutDeputyEditRef = useRef(null);
	const headerRef = useRef(null);

	const size = useSize(headerRef);

	const responsive = useResponsive();

	useWatch(loading, () => {
		doLoading(loading);
	});

	useWatch(size, () => {
		let flag = window.navigator.userAgent.match(
			/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
		);
		if (flag) {
			return;
		}
		//
		let radio = Math.round(window.devicePixelRatio * 100);
		if (radio < 100) {
			// if (localStorage.getItem('NCM_showRadioMessage')) {
			// 	return;
			// }
			// localStorage.setItem('NCM_showRadioMessage', true);
			// const iconError = () => {
			// 	return <img className="ICONinfo" alt="Error" src={ICONinfo} />;
			// };
			// const description = () => {
			// 	return (
			// 		<Fragment>
			// 			<div>{t('Error.Scale')}</div>
			// 		</Fragment>
			// 	);
			// };
			// notification.open({
			// 	message: t('Detail.cancel1'),
			// 	duration: 5,
			// 	description: description(),
			// 	icon: iconError(),
			// 	key: 'zoom',
			// });
		} else {
			// notification.close('zoom');
			// localStorage.removeItem('NCM_showRadioMessage');
			// document.body.style.zoom = 1;
			// document.body.style.zoom = -0.6 * t + 1.55;
			// document.body.style.zoom = 1 / window.devicePixelRatio;
		}
	});

	useEffect(() => {
		if (i18n.language === 'en') {
			setLocale(localeEN);
		} else if (i18n.language === 'de') {
			setLocale(localeDE);
		} else if (i18n.language === 'fr') {
			setLocale(localeFR);
		} else if (i18n.language === 'es') {
			setLocale(localeES);
		} else if (i18n.language === 'pt') {
			setLocale(localePT);
		}
	}, [i18n.language]);

	const [flyoutOpen, setFlyoutOpen] = useState(false);
	const [user, setUser] = useState({});
	const [loggedIn, setLoggedIn] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const [isChangeProfile, setIsChangeProfile] = useState(false);
	const [isUsertoDeputy, setIsUsertoDeputy] = useState(false);
	const [activeClass, setActiveClass] = useState(
		localStorage.getItem('NCM_lang') ? localStorage.getItem('NCM_lang') : 'en'
	);
	const [flyoutContent, setFlyoutContent] = useState('');
	const [deputyAction, setDeputyAction] = useState('');
	const [isAccessibility, setIsAccessibility] = useState(false);
	// DeputyData
	const [deputyData, setDeputyData] = useState([]);
	const [deputyDetail, setDeputyDetail] = useState({});
	//
	const authCode = useQuery();
	const history = useHistory();

	useMount(() => {
		// stop browser back
		window.history.pushState(null, null, document.URL);
		window.onpopstate = function (e) {
			window.history.pushState(null, null, document.URL);
		};
		//
		setFlyoutOpen(false);
		setFlyoutContent('');
		checkUser();
	});

	const checkUser = async () => {
		// const NCM_Token =
		// 	'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ6aGktY2hhbmcuemhvdUBiYXNmLmNvbSIsImlzcyI6ImZlZWRiYWNrUG9ydGFsVXNlciIsImp0aSI6IjAyNmZmZDBjLTAyYjUtNDg1NC04MjA5LTk4NjhlZTFkNWY2NCIsImlhdCI6MTcyNTAwMzU2M30.naVupE54r1CbonAc3MScP6zHaq1mhR8D_V4PBMVL7Ks';
		// const NCM_AuthorizationTime = Date.parse(new Date())
		// 	.toString()
		// 	.substr(0, 10);
		// const NCM_User =
		// 	'{"firstName":"Zhi","lastName":"ZHOU","email":"zhi-chang.zhou@basf.com","fullName":null,"basfId":"zhi-chang.zhou@basf.com","groupMembership":["cn=SG_NCM_FEEDBACK,ou=Groups,ou=3IAM,o=EXTERNAL"]}';
		// localStorage.setItem('NCM_Token', NCM_Token);
		// localStorage.setItem('NCM_AuthorizationTime', NCM_AuthorizationTime);
		// localStorage.setItem('NCM_User', NCM_User);

		if (localStorage.getItem('NCM_Announcement')) {
			const { href } = window.location;
			const code =
				href.indexOf('code=') > -1
					? href.split('?code=')[1].replace('&scope=UserProfileService', '')
					: null;
			if (typeof code !== 'string') {
				localStorage.removeItem('NCM_Announcement');
				return;
			}
			//
			history.push(`/8dreport?code=${code}`);
			localStorage.removeItem('NCM_Announcement');
			return;
		}

		if (
			!localStorage.getItem('NCM_lang') ||
			localStorage.getItem('NCM_lang') === ''
		) {
			localStorage.setItem('NCM_lang', 'en');
		}
		//
		if (
			localStorage.getItem('NCM_AuthorizationTime') &&
			localStorage.getItem('NCM_AuthorizationTime') !== ''
		) {
			let newtime = Date.parse(new Date()).toString();
			newtime = newtime.substr(0, 10);
			const oldtime = localStorage.getItem('NCM_AuthorizationTime');
			if (Number.parseInt(newtime) - Number.parseInt(oldtime) > 12 * 60 * 60) {
				logout('nolock');
				return;
			}
		}
		if (
			localStorage.getItem('NCM_AuthorizationTime') &&
			localStorage.getItem('NCM_Token')
		) {
			//
			if (location.pathname === '/') {
				history.replace('/landing');
			}
			//
			let newtime = Date.parse(new Date()).toString();
			newtime = newtime.substr(0, 10);
			const oldtime = localStorage.getItem('NCM_AuthorizationTime');
			//
			if (Number.parseInt(newtime) - Number.parseInt(oldtime) > 12 * 60 * 60) {
				logout('nolock');
				return;
			}
			//
			toggleLoading();
			// const obj = {
			// 	accessToken: `${localStorage.getItem('NCM_Authorization')}`,
			// };
			// const [err, userResponse] = await awaitWrap(
			// 	axios.post('/feedbackServices/authorize/user-info', obj)
			// );
			// if (err) {
			// 	toggleLoading();
			// 	logout('nolock');
			// 	return;
			// }
			// console.log(userResponse);
			// //
			// if (
			// 	!userResponse.data.email.toUpperCase().includes('@BASF.COM') &&
			// 	!userResponse.data.email.toUpperCase().includes('@PARTNERS.BASF.COM') &&
			// 	userResponse.data.groupMembership
			// ) {
			// 	const group = Array.isArray(userResponse.data.groupMembership)
			// 		? userResponse.data.groupMembership.join(',')
			// 		: userResponse.data.groupMembership;
			// 	console.log(group);
			// 	const groupCheck = group.includes('SG_NCM_FEEDBACK');
			// 	if (!groupCheck) {
			// 		const iconError = () => {
			// 			return <img className="ICONinfo" alt="Error" src={ICONinfo} />;
			// 		};
			// 		const normalButton = {
			// 			marginLeft: '10px',
			// 			color: '#fff',
			// 			background: '#1fa1d2',
			// 			border: '1px solid #1fa1d2',
			// 			padding: '10px 0',
			// 			width: '110px',
			// 			textAlign: 'center',
			// 		};
			// 		const blueButton = {
			// 			color: '#1fa1d2',
			// 			background: '#fff',
			// 			border: '2px solid #1fa1d2',
			// 			padding: '10px 0',
			// 			width: '110px',
			// 			textAlign: 'center',
			// 		};
			// 		const btn = () => {
			// 			return (
			// 				<Fragment>
			// 					<div>
			// 						Your account is missing access rights for Non-Conformance
			// 						Feedback Portal Project. To apply for an account, please
			// 						register here.
			// 					</div>
			// 					<div style={{ marginTop: '10px' }}>
			// 						<button style={blueButton}>Cancel</button>
			// 						<button style={normalButton}>Register</button>
			// 					</div>
			// 				</Fragment>
			// 			);
			// 		};
			// 		toggleLoading();
			// 		notification.open({
			// 			message: 'Attention',
			// 			duration: 0,
			// 			description: btn(),
			// 			icon: iconError(),
			// 		});
			// 		localStorage.removeItem('NCM_Token');
			// 		localStorage.removeItem('NCM_AuthorizationTime');
			// 		return;
			// 	}
			// }
			//
			let userBak = JSON.parse(localStorage.getItem('NCM_User_bak'));
			if (userBak && JSON.stringify(userBak) !== '{}') {
				// localStorage.setItem('NCM_User_bak', JSON.stringify(userResponse.data));
				setIsChangeProfile(true);
			}
			let user = JSON.parse(localStorage.getItem('NCM_User'));
			setUser(user);
			//
			changeShowDeputy(
				localStorage.getItem('NCM_showDeputy') === '1' ? true : false
			);
			//
			await unLockFetch();
			//
			setLoggedIn(true);
			localStorage.removeItem('NCM_landing_popup');
			//
			let userTemp = JSON.parse(localStorage.getItem('NCM_User'));
			//
			// checkadmin
			let emaildata = userTemp.email;
			// if (userBak && JSON.stringify(userBak) !== '{}') {
			// 	emaildata = userTemp.email;
			// } else {
			// 	emaildata = userResponse.data.email;
			// }
			let admin = await axios.post('/feedbackServices/apiUser/getUserInfo', {
				email: emaildata,
			});
			//
			if (admin && admin.data && admin.data.isAdmin === 'true') {
				setIsAdmin(true);
			}
			// fetchDeputy
			const deputyObj = {
				imemail: emaildata,
			};
			const res = await axios.post(
				'/feedbackServices/portalSap/fetchDeputy',
				deputyObj
			);
			if (res.data.etdeputy && res.data.etdeputy.deputies) {
				setDeputyData(res.data.etdeputy.deputies);
			}
			//
			if (userBak && JSON.stringify(userBak) !== '{}') {
				//
			} else {
				let imlangu = '';
				if (localStorage.getItem('NCM_lang') === 'en') {
					imlangu = 'E';
				} else if (localStorage.getItem('NCM_lang') === 'de') {
					imlangu = 'D';
				} else if (localStorage.getItem('NCM_lang') === 'fr') {
					imlangu = 'F';
				} else if (localStorage.getItem('NCM_lang') === 'es') {
					imlangu = 'S';
				} else if (localStorage.getItem('NCM_lang') === 'pt') {
					imlangu = 'P';
				} else {
					imlangu = 'E';
				}
				let deputyobj = {
					immainpartner: user.email,
					imop: 'U',
					imlangu: imlangu,
				};
				await axios.post(
					'/feedbackServices/portalSap/maintainDeputy',
					deputyobj
				);
			}
			//
			doLogin(true);
			toggleLoading();
			//
			return;
		}
		if (authCode === '' || authCode === null) {
			if (
				location.pathname === '/landing' ||
				location.pathname === '/overview' ||
				location.pathname === '/detail'
			) {
				history.replace('/');
			}
		} else {
			toggleLoading();
			let loginPath = '/feedbackServices/authorize/token-info?code=';
			loginPath += authCode;
			const [err, response] = await awaitWrap(axios.get(loginPath));
			if (err) {
				toggleLoading();
				logout('nolock');
				return;
			}
			if (
				response.data.accessToken ===
				'invalid_code: code invalid or already used'
			) {
				toggleLoading();
				logout('nolock');
			} else {
				//
				localStorage.removeItem('NCM_User_bak');
				//
				// localStorage.setItem('NCM_Authorization', response.data.accessToken);
				let time = Date.parse(new Date()).toString();
				time = time.substr(0, 10);
				localStorage.setItem('NCM_AuthorizationTime', time);
				// user account
				const obj = {
					accessToken: response.data.accessToken,
				};
				const userResponse = await axios.post(
					'/feedbackServices/authorize/user-info',
					obj
				);
				//
				if (
					!userResponse.data.email.toUpperCase().includes('@BASF.COM') &&
					!userResponse.data.email
						.toUpperCase()
						.includes('@PARTNERS.BASF.COM') &&
					userResponse.data.groupMembership
				) {
					const group = Array.isArray(userResponse.data.groupMembership)
						? userResponse.data.groupMembership.join(',')
						: userResponse.data.groupMembership;
					// console.log(group);
					const groupCheck = group.includes('SG_NCM_FEEDBACK');
					if (!groupCheck) {
						const iconError = () => {
							return <img className="ICONinfo" alt="Error" src={ICONinfo} />;
						};
						const normalButton = {
							marginLeft: '10px',
							color: '#fff',
							background: '#1fa1d2',
							border: '1px solid #1fa1d2',
							padding: '10px 0',
							width: '110px',
							textAlign: 'center',
						};
						const blueButton = {
							color: '#1fa1d2',
							background: '#fff',
							border: '2px solid #1fa1d2',
							padding: '10px 0',
							width: '110px',
							textAlign: 'center',
						};
						const btn = () => {
							return (
								<Fragment>
									<div>
										Your account is missing access rights for Non-Conformance
										Feedback Portal Project. To apply for an account, please
										register here.
									</div>
									<div style={{ marginTop: '10px' }}>
										<button style={blueButton}>Cancel</button>
										<button
											style={normalButton}
											onClick={() => history.push('/register')}
										>
											Register
										</button>
									</div>
								</Fragment>
							);
						};
						toggleLoading();
						notification.open({
							message: 'Attention',
							duration: 0,
							description: btn(),
							icon: iconError(),
						});
						localStorage.removeItem('NCM_Token');
						localStorage.removeItem('NCM_AuthorizationTime');
						return;
					}
				}
				//
				let tokentemp = await axios.get(
					`/feedbackServices/authorize/getServiceToken/${userResponse.data.email}`
				);
				localStorage.setItem('NCM_Token', tokentemp.data);
				//
				localStorage.setItem('NCM_User', JSON.stringify(userResponse.data));
				setUser(userResponse.data);
				//
				setLoggedIn(true);
				localStorage.removeItem('NCM_landing_popup');
				// checkadmin
				let emaildata = userResponse.data.email;
				let admin = await axios.post('/feedbackServices/apiUser/getUserInfo', {
					email: emaildata,
				});
				if (admin && admin.data && admin.data.isAdmin === 'true') {
					setIsAdmin(true);
				}
				//
				if (admin && admin.data === '') {
					let userobj = {
						id: userResponse.data.email,
						first_name: userResponse.data.firstName,
						last_name: userResponse.data.lastName,
						status: 'active',
						operation: 'EnableUser',
						isAdmin: 'false',
						isDeleted: 'false',
					};
					await axios.post('/feedbackServices/apiUser/createUserInfo', userobj);
				}
				// fetchDeputy
				const deputyObj = {
					imemail: emaildata,
				};
				const res = await axios.post(
					'/feedbackServices/portalSap/fetchDeputy',
					deputyObj
				);
				if (res.data.etdeputy && res.data.etdeputy.deputies) {
					setDeputyData(res.data.etdeputy.deputies);
				}
				// update deputy
				let imlangu = '';
				if (localStorage.getItem('NCM_lang') === 'en') {
					imlangu = 'E';
				} else if (localStorage.getItem('NCM_lang') === 'de') {
					imlangu = 'D';
				} else if (localStorage.getItem('NCM_lang') === 'fr') {
					imlangu = 'F';
				} else if (localStorage.getItem('NCM_lang') === 'es') {
					imlangu = 'S';
				} else if (localStorage.getItem('NCM_lang') === 'pt') {
					imlangu = 'P';
				} else {
					imlangu = 'E';
				}
				let deputyobj = {
					immainpartner: userResponse.data.email,
					imop: 'U',
					imlangu: imlangu,
				};
				await axios.post(
					'/feedbackServices/portalSap/maintainDeputy',
					deputyobj
				);
				//
				await unLockFetch();
				//
				doLogin(true);
				//
				toggleLoading();
				history.replace('/landing');
			}
		}
	};

	const fetchDeputy = async () => {
		let user = JSON.parse(localStorage.getItem('NCM_User'));
		const deputyObj = {
			imemail: user.email,
		};
		toggleLoading();
		const res = await axios.post(
			'/feedbackServices/portalSap/fetchDeputy',
			deputyObj
		);
		toggleLoading();
		if (res.data && res.data.etdeputy && res.data.etdeputy.deputies) {
			setDeputyData(res.data.etdeputy.deputies);
		}
	};

	const handleDeputy = (val, item) => {
		setDeputyAction(val);
		setDeputyDetail(item);
	};

	const unLockFetch = async () => {
		let nc = localStorage.getItem('NCM_lockId');
		let user = JSON.parse(localStorage.getItem('NCM_User'));
		if (nc && nc !== '') {
			const [err, userResponse] = await awaitWrap(
				axios.post(
					`feedbackServices/apiResource/updateResource?qmnum=${nc}&flag=false&userId=${user.email}`,
					{}
				)
			);
			// console.log(err, userResponse);
			localStorage.removeItem('NCM_lockId');
		}
	};

	const logout = async (val) => {
		//
		if (val === 'lock') {
			toggleLoading();
			await unLockFetch();
			toggleLoading();
		}
		//
		let user = JSON.parse(localStorage.getItem('NCM_User'));
		//
		localStorage.removeItem('NCM_Token');
		localStorage.removeItem('NCM_User');
		localStorage.removeItem('NCM_User_bak');
		localStorage.removeItem('NCM_AuthorizationTime');
		localStorage.removeItem('NCM_landing_popup');
		localStorage.removeItem('NCM_searchval');
		localStorage.removeItem('NCM_filterval');
		localStorage.removeItem('NCM_expandedAll');
		localStorage.removeItem('NCM_scrollY');
		localStorage.removeItem('NCM_lockId');
		localStorage.removeItem('NCM_lang_bak');
		setLoggedIn(false);
		setUser({});
		//
		if (user.email.toLowerCase().includes('@basf.com')) {
			history.replace('/internal');
		} else {
			history.replace('/');
		}
		//
		setTimeout(() => {
			window.location.reload();
		}, 10);
	};

	const toggleFlyout = () => {
		if (isUsertoDeputy) {
			setFlyoutContent('profile');
			setDeputyAction('');
			setIsUsertoDeputy(false);
			setFlyoutOpen(true);
		} else {
			setFlyoutOpen(!flyoutOpen);
			setFlyoutContent('');
			setDeputyAction('');
			setIsUsertoDeputy(false);
		}
	};

	const changeFlyoutContent = (content) => {
		if (content === 'userdeputy') {
			setFlyoutContent('deputy');
			setIsUsertoDeputy(true);
		} else {
			setFlyoutContent(content);
			setIsUsertoDeputy(false);
		}
		setFlyoutOpen(true);
	};

	const changeAccessibility = () => {
		if (isAccessibility) {
			// console.log('accessability mode off');
			setIsAccessibility(false);
			let root = document.documentElement;
			root.style.filter = '';
			root.style.webkitFilter = '';
			root.style.MozFilter = '';
			root.style.OFilter = '';
			root.style.msFilter = '';
		} else {
			// console.log('accessability mode on');
			let root = document.documentElement;
			root.style.filter = 'gray';
			root.style.filter = 'grayscale(100%)';
			root.style.filter =
				" url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale\")";
			root.style.filter =
				'progid:DXImageTransform.Microsoft.BasicImage(grayscale=1)';
			root.style.webkitFilter = 'grayscale(100%)';
			root.style.webkitFilter = 'grayscale(1)';
			root.style.MozFilter = 'grayscale(100%)';
			root.style.OFilter = 'grayscale(100%)';
			root.style.msFilter = 'grayscale(100%)';
			setIsAccessibility(true);
		}
	};

	const changelang = (val) => {
		if (!detailIsPrompt) {
			localStorage.setItem('NCM_lang', val);
			window.location.reload();
			i18n.changeLanguage(val);
			setActiveClass(val);
		} else {
			localStorage.setItem('NCM_lang_bak', val);
			changeLanguagePopup(true);
		}
	};

	const gotoPage = (val) => {
		setFlyoutOpen(false);
		setUrllink(val);
		history.push(val);
	};

	const backUser = () => {
		if (location.pathname === '/detail') {
			history.replace('/landing');
			changeUserProfileDetail(true);
			return;
		}
		let userBak = JSON.parse(localStorage.getItem('NCM_User_bak'));
		localStorage.removeItem('NCM_User_bak');
		localStorage.setItem('NCM_User', JSON.stringify(userBak));
		setIsChangeProfile(false);
		setTimeout(() => {
			window.location.reload();
		}, 10);
	};

	const pcNav = () => {
		if (!isAdmin) {
			return (
				<Fragment>
					<li>
						<NavLink to="/landing" activeClassName="current">
							{t('Header.Home')}
						</NavLink>
					</li>
					<li>
						<NavLink to="/overview" activeClassName="current">
							{t('Header.Overview')}
						</NavLink>
					</li>
				</Fragment>
			);
		} else {
			if (
				location.pathname === '/adminhome' ||
				location.pathname === '/adminuser' ||
				location.pathname === '/contentmanagement'
			) {
				return (
					<Fragment>
						<li>
							<NavLink to="/landing" activeClassName="current">
								{t('Header.Home')}
							</NavLink>
						</li>
						<li>
							<NavLink to="/overview" activeClassName="current">
								{t('Header.Overview')}
							</NavLink>
						</li>
						<li>
							<NavLink to="/adminhome" activeClassName="current">
								User Administration
							</NavLink>
						</li>
						<li>
							<NavLink to="/contentmanagement" activeClassName="current">
								Content Management
							</NavLink>
						</li>
					</Fragment>
				);
			} else {
				return (
					<Fragment>
						<li>
							<NavLink to="/landing" activeClassName="current">
								{t('Header.Home')}
							</NavLink>
						</li>
						<li>
							<NavLink to="/overview" activeClassName="current">
								{t('Header.Overview')}
							</NavLink>
						</li>
						<li>
							<NavLink to="/adminhome" activeClassName="current">
								User Administration
							</NavLink>
						</li>
						<li>
							<NavLink to="/contentmanagement" activeClassName="current">
								Content Management
							</NavLink>
						</li>
						{/* <li>
							<NavLink to="/configuration" activeClassName="current">
								Access Page
							</NavLink>
						</li> */}
					</Fragment>
				);
			}
		}
	};

	const mobileNav = () => {
		if (!isAdmin) {
			return (
				<Fragment>
					<li>
						<span onClick={() => gotoPage('/landing')}>{t('Header.Home')}</span>
					</li>
					<li>
						<span onClick={() => gotoPage('/overview')}>
							{t('Header.Overview')}
						</span>
					</li>
				</Fragment>
			);
		} else {
			if (
				location.pathname === '/adminhome' ||
				location.pathname === '/adminuser' ||
				location.pathname === '/contentmanagement'
			) {
				return (
					<Fragment>
						<li>
							<span onClick={() => gotoPage('/landing')}>
								{t('Header.Home')}
							</span>
						</li>
						<li>
							<span onClick={() => gotoPage('/overview')}>
								{t('Header.Overview')}
							</span>
						</li>
						<li>
							<span onClick={() => gotoPage('/adminhome')}>
								User Administration
							</span>
						</li>
						<li>
							<span onClick={() => gotoPage('/contentmanagement')}>
								Content Management
							</span>
						</li>
					</Fragment>
				);
			} else {
				return (
					<Fragment>
						<li>
							<span onClick={() => gotoPage('/landing')}>
								{t('Header.Home')}
							</span>
						</li>
						<li>
							<span onClick={() => gotoPage('/overview')}>
								{t('Header.Overview')}
							</span>
						</li>
						<li>
							<span onClick={() => gotoPage('/adminhome')}>
								User Administration
							</span>
						</li>
						<li>
							<span onClick={() => gotoPage('/contentmanagement')}>
								Content Management
							</span>
						</li>
					</Fragment>
				);
			}
		}
	};

	return (
		<header
			ref={headerRef}
			className={flyoutOpen ? 'covered print_hide' : 'print_hide'}
		>
			{isChangeProfile && !flyoutOpen ? (
				<div className="adminbarwrap">
					<div className="adminbar">
						<span className="adminbarleft">
							You are currently viewing the profile of&nbsp;
							<span className="useremail">{user.email}</span>
						</span>
						<span className="adminbarright" onClick={() => backUser()}>
							Return to your profile
						</span>
					</div>
				</div>
			) : (
				<div className="emptybar">&nbsp;</div>
			)}
			<div
				className={
					flyoutOpen ? 'aside asideLeft transparent' : 'aside asideLeft'
				}
			/>
			<div className="headerBar">
				<div className={flyoutOpen ? 'leftBar transparent' : 'leftBar'}>
					{loggedIn ? (
						<nav>
							<button
								className={flyoutOpen ? 'btnMenu always' : 'btnMenu'}
								onClick={() => toggleFlyout()}
							>
								{flyoutOpen ? (
									<img alt="close" src={ICONclose} />
								) : (
									<img alt="menu" src={ICONmenu} />
								)}
							</button>

							<ul className={flyoutOpen ? 'lstNav hidden' : 'lstNav'}>
								{pcNav()}
							</ul>
							<button
								className={flyoutOpen ? 'btnLogout' : 'btnLogout hidden'}
								onClick={() => logout('lock')}
							>
								<span>{t('Deputy.Logout')}</span>
								<img alt="logout" src={ICONlogout} />
							</button>

							<ul className={flyoutOpen ? 'lstIcons hidden' : 'lstIcons'}>
								{showDeputy === false ? (
									<Fragment />
								) : (
									<li>
										{responsive.large ? (
											<Tooltip
												title={t('Header.DeputyManagement')}
												placement="bottom"
												overlayClassName="headertooltip"
											>
												<button onClick={() => changeFlyoutContent('deputy')}>
													<img
														className="big"
														alt="deputy manage"
														src={ICONdeputyman}
													/>
												</button>
											</Tooltip>
										) : (
											<button onClick={() => changeFlyoutContent('deputy')}>
												<img
													className="big"
													alt="deputy manage"
													src={ICONdeputyman}
												/>
											</button>
										)}
									</li>
								)}
								<li>
									{responsive.large ? (
										<Tooltip
											title={t('FAQ.category3-answer4-2')}
											placement="bottom"
											overlayClassName="headertooltip"
										>
											<button onClick={() => changeFlyoutContent('profile')}>
												<img className="small" alt="user" src={ICONuser} />
											</button>
										</Tooltip>
									) : (
										<button onClick={() => changeFlyoutContent('profile')}>
											<img className="small" alt="user" src={ICONuser} />
										</button>
									)}
								</li>
								<li>
									{responsive.large ? (
										<Tooltip
											title={t('Header.LanguageSelection')}
											placement="bottom"
											overlayClassName="headertooltip"
										>
											<button onClick={() => changeFlyoutContent('languages')}>
												<img alt="languages" src={ICONlanguages} />
											</button>
										</Tooltip>
									) : (
										<button onClick={() => changeFlyoutContent('languages')}>
											<img alt="languages" src={ICONlanguages} />
										</button>
									)}
								</li>
								<li>
									{responsive.large ? (
										<Tooltip
											title={t('Header.Accessibilitymode')}
											placement="bottom"
											overlayClassName="headertooltip"
										>
											<button onClick={() => changeAccessibility()}>
												<img alt="languages" src={ICONAccessibility} />
											</button>
											{isAccessibility ? (
												<span className="hide">accessability mode on</span>
											) : (
												<span className="hide">accessability mode off</span>
											)}
										</Tooltip>
									) : (
										<Fragment>
											<button onClick={() => changeAccessibility()}>
												<img alt="languages" src={ICONAccessibility} />
											</button>
											{isAccessibility ? (
												<span className="hide">accessability mode on</span>
											) : (
												<span className="hide">accessability mode off</span>
											)}
										</Fragment>
									)}
								</li>
								<li>
									{responsive.large ? (
										<Tooltip
											title={t('Deputy.Logout')}
											placement="bottom"
											overlayClassName="headertooltip"
										>
											<button onClick={() => logout('lock')}>
												<img
													className="logoutimage"
													alt="languages"
													src={ICONlogoutGrey}
												/>
											</button>
										</Tooltip>
									) : (
										<button onClick={() => logout('lock')}>
											<img
												className="logoutimage"
												alt="languages"
												src={ICONlogoutGrey}
											/>
										</button>
									)}
								</li>
							</ul>
						</nav>
					) : (
						<nav>
							<button
								className={flyoutOpen ? 'btnMenu always' : 'btnMenu'}
								onClick={() => setFlyoutOpen(false)}
							>
								{flyoutOpen ? <img alt="close" src={ICONclose} /> : null}
							</button>
							<ul
								className={flyoutOpen ? 'lstIcons hidden' : 'lstIcons always'}
							>
								<li>
									{responsive.large ? (
										<Tooltip
											title={t('Header.LanguageSelection')}
											placement="bottom"
											overlayClassName="headertooltip"
										>
											<button onClick={() => changeFlyoutContent('languages')}>
												<img alt="languages" src={ICONlanguages} />
											</button>
											{isAccessibility ? (
												<span className="hide">accessability mode on</span>
											) : (
												<span className="hide">accessability mode off</span>
											)}
										</Tooltip>
									) : (
										<Fragment>
											<button onClick={() => changeFlyoutContent('languages')}>
												<img alt="languages" src={ICONlanguages} />
											</button>
											{isAccessibility ? (
												<span className="hide">accessability mode on</span>
											) : (
												<span className="hide">accessability mode off</span>
											)}
										</Fragment>
									)}
								</li>
								<li>
									{responsive.large ? (
										<Tooltip
											title={t('Header.Accessibilitymode')}
											placement="bottom"
											overlayClassName="headertooltip"
										>
											<button onClick={() => changeAccessibility()}>
												<img alt="languages" src={ICONAccessibility} />
												{isAccessibility ? (
													<span className="hide">accessability mode on</span>
												) : (
													<span className="hide">accessability mode off</span>
												)}
											</button>
										</Tooltip>
									) : (
										<Fragment>
											<button onClick={() => changeAccessibility()}>
												<img alt="languages" src={ICONAccessibility} />
											</button>
											{isAccessibility ? (
												<span className="hide">accessability mode on</span>
											) : (
												<span className="hide">accessability mode off</span>
											)}
										</Fragment>
									)}
								</li>
							</ul>
						</nav>
					)}
				</div>
				<div className="rightBar">
					<h1 className="lnkLogo">
						<NavLink to="/landing">
							<img alt="company logo" src={ICONlogo} />
						</NavLink>
					</h1>
				</div>
			</div>
			<div className="aside asideRight" />
			{flyoutOpen ? (
				<div className="flyoutContent">
					{flyoutContent === 'profile' ? (
						<FlyoutProfile
							user={user}
							changeFlyoutContent={changeFlyoutContent}
							activeClass={activeClass}
							changelang={changelang}
						/>
					) : flyoutContent === 'deputy' ? (
						<>
							<div className="deputy">
								<FlyoutDeputyList
									deputyAction={deputyAction}
									handleDeputy={handleDeputy}
									deputyData={deputyData}
									fetchDeputy={fetchDeputy}
								/>
								{deputyAction === 'edit' ? (
									<FlyoutDeputyEdit
										ref={flyoutDeputyEditRef}
										moment={moment}
										locale={locale}
										handleDeputy={handleDeputy}
										fetchDeputy={fetchDeputy}
										deputyDetail={deputyDetail}
									/>
								) : deputyAction === 'add' ? (
									<FlyoutDeputyAdd
										ref={flyoutDeputyAddRef}
										moment={moment}
										locale={locale}
										handleDeputy={handleDeputy}
										fetchDeputy={fetchDeputy}
									/>
								) : (
									<Fragment />
								)}
							</div>
						</>
					) : flyoutContent === 'languages' ? (
						<FlyoutLanguage activeClass={activeClass} changelang={changelang} />
					) : (
						<>
							<ul className="lstIconsAlt">
								<li>
									<button onClick={() => changeAccessibility()}>
										<img alt="languages" src={ICONAccessibility} />
									</button>
									{isAccessibility ? (
										<span className="hide">accessability mode on</span>
									) : (
										<span className="hide">accessability mode off</span>
									)}
								</li>
								<li>
									<button onClick={() => changeFlyoutContent('languages')}>
										<img alt="languages" src={ICONlanguages} />
									</button>
								</li>
								<li>
									<button onClick={() => changeFlyoutContent('profile')}>
										<img className="small" alt="user" src={ICONuser} />
									</button>
								</li>
								{showDeputy === false ? (
									<Fragment />
								) : (
									<li>
										<button onClick={() => changeFlyoutContent('deputy')}>
											<img
												className="big"
												alt="deputy manage"
												src={ICONdeputyman}
											/>
										</button>
									</li>
								)}
							</ul>
							<ul className="lstMenu">{mobileNav()}</ul>
						</>
					)}
				</div>
			) : (
				<Fragment />
			)}
		</header>
	);
};

export default Header;
