import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ICONaccount from '../../assets/icons/account.svg';
import ICONdashboard from '../../assets/icons/dashboard.svg';
import ICONoverview from '../../assets/icons/overview.svg';
import ICONrequest from '../../assets/icons/request.svg';
import ICONresponsive from '../../assets/icons/responsive.svg';
import ICONworkflow from '../../assets/icons/workflow.svg';
import IMAGEstartMedium from '../../assets/images/start_med.png';
import '../Start/Start.scss';
import axios from '../../axios-custom';
import store from '../../state/store';
import { Modal } from 'antd';
import '../../../node_modules/antd/lib/modal/style/index.css';
import ICONclose from '../../assets/icons/closeWhite.svg';
import { useMount } from 'ahooks';
const { useModel } = store;

const Start = (props) => {
	const { t } = useTranslation();

	const [isModalVisible, setIsModalVisible] = useState(false);
	const [content, setContent] = useState('');

	// eslint-disable-next-line no-unused-vars
	const [state, dispatchers] = useModel('global');
	const { toggleLoading } = dispatchers;

	useEffect(() => {
		props.bgHandler(true, false, '', false);
	}, [props]);

	useMount(async () => {
		toggleLoading();
		let data = await axios.get('/feedbackServices/api/getPublicNotification');
		// console.log(data);
		if (data.data.isDeactivate === false) {
			setContent(data.data[`${localStorage.getItem('NCM_lang')}message`]);
			setIsModalVisible(true);
		} else {
			setIsModalVisible(false);
		}
		toggleLoading();
	});

	// Federation Login Iniator
	const goFed = () => {
		let redirectUrl = '';
		let client_id = '';
		let federation = '';
		if (window.location.origin.includes('localhost')) {
			redirectUrl = 'https://qual.ncm.basf.com';
			client_id = '355006f9-603c-4642-a38a-6460e0737b57';
			federation = 'https://federation-qa.basf.com/nidp/oauth/nam/authz';
		} else if (window.location.origin.includes('dev')) {
			redirectUrl = 'https://dev.ncm.basf.com';
			client_id = 'a9526525-7323-41db-8a3f-955090557519';
			federation = 'https://federation.basf.com/nidp/oauth/nam/authz';
		} else if (window.location.origin.includes('qual')) {
			redirectUrl = 'https://qual.ncm.basf.com';
			client_id = '355006f9-603c-4642-a38a-6460e0737b57';
			federation = 'https://federation-qa.basf.com/nidp/oauth/nam/authz';
		} else {
			redirectUrl = 'https://ncm.basf.com';
			client_id = 'a9526525-7323-41db-8a3f-955090557519';
			federation = 'https://federation.basf.com/nidp/oauth/nam/authz';
		}
		const fedPath = `${federation}?client_id=${client_id}&scope=UserProfileService&response_type=code&redirect_uri=${encodeURIComponent(
			redirectUrl
		)}`;
		document.location.href = fedPath;
	};

	return (
		<section className="start">
			<Modal
				open={isModalVisible}
				className="tipsModal"
				footer={null}
				closable={false}
				centered
				getContainer={false}
			>
				<div className="contentinto">
					<div
						className="contentwrap"
						dangerouslySetInnerHTML={{
							__html: content,
						}}
					></div>
					<div className="buttongroup">
						<button
							className="btnCancel"
							onClick={() => setIsModalVisible(false)}
						>
							OK
						</button>
					</div>
				</div>
				<div className="closeicon" onClick={() => setIsModalVisible(false)}>
					<img alt="close" src={ICONclose} />
				</div>
			</Modal>
			<div className="banner">
				<h4>{t('Start.title')}</h4>
				<h3>{t('Start.subTitle')}</h3>
				{!props.isUserLogin ? (
					<div className="actions">
						{/* <Link className="btnRegister" to="/register">
							{t('Start.register')}
						</Link> */}
						<button className="btnLogin" onClick={goFed}>
							{t('Start.login')}
						</button>
					</div>
				) : (
					<div className="actions"></div>
				)}
				<img alt="People" className="imagStart" src={IMAGEstartMedium} />
			</div>
			<div className="features-background">
				<div className="features">
					<h4>{t('Start.featureName')}</h4>
					<ul className="listFeatures">
						<li>
							<img
								alt="Secure Environment"
								className="iconFeature"
								src={ICONaccount}
							/>
							<div className="description">
								<h5>{t('Start.SEtitle')}</h5>
								<p>{t('Start.SEcontent')}</p>
							</div>
						</li>
						<li>
							<img
								alt="Optimized Workflow"
								className="iconFeature"
								src={ICONworkflow}
							/>
							<div className="description">
								<h5>{t('Start.OWtitle')}</h5>
								<p>{t('Start.OWcontent')}</p>
							</div>
						</li>
						<li>
							<img
								alt="Responsive UI"
								className="iconFeature"
								src={ICONresponsive}
							/>
							<div className="description">
								<h5>{t('Start.RUtitle')}</h5>
								<p>{t('Start.RUcontent')}</p>
							</div>
						</li>
						<li>
							<img
								alt="Personalized Dashboard"
								className="iconFeature"
								src={ICONdashboard}
							/>
							<div className="description">
								<h5>{t('Start.PDtitle')}</h5>
								<p>{t('Start.PDcontent')}</p>
							</div>
						</li>
						<li>
							<img
								alt="Integrated Form"
								className="iconFeature"
								src={ICONrequest}
							/>
							<div className="description">
								<h5>{t('Start.IFtitle')}</h5>
								<p>{t('Start.IFcontent')}</p>
							</div>
						</li>
						<li>
							<img
								alt="Quick Overview"
								className="iconFeature"
								src={ICONoverview}
							/>
							<div className="description">
								<h5>{t('Start.QOtitle')}</h5>
								<p>{t('Start.QOcontent')}</p>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</section>
	);
};

export default Start;
