import React, { useImperativeHandle, useState, memo, Fragment } from 'react';
import { useReactive, useMemoizedFn, useResponsive } from 'ahooks';
import { useTranslation } from 'react-i18next';
import DropdownList from 'react-dropdown';
import ICONdown from '../../../assets/icons/arrowdownfix.svg';
import ICONup from '../../../assets/icons/arrow-up.svg';
import ICONfilter from '../../../assets/icons/filter.svg';
import ICONsearch from '../../../assets/icons/searchfix.svg';
import ICONclose from '../../../assets/icons/closefix.svg';
import ICONradioOFF from '../../../assets/icons/toggle-inactiv.svg';
import ICONradioON from '../../../assets/icons/toggle-activ.svg';
import './InputRadio.scss';
import { Affix, Badge } from 'antd';
import '../../../../node_modules/antd/lib/affix/style/index.css';
import '../../../../node_modules/antd/lib/badge/style/index.css';

const LettersOverviewHeader = React.forwardRef((props, ref) => {
	const { t } = useTranslation();
	const responsive = useResponsive();

	const [periodOptions, setperiodOptions] = useState([
		{ value: '3', label: t('Overview.Last3Months') },
		{ value: '6', label: t('Overview.Last6Months') },
		{ value: '12', label: t('Overview.Last12Months') },
	]);

	useImperativeHandle(ref, () => ({
		changeSearchVal: (val) => {
			setsearchVal(val);
			LOsearchForm.srchString = val;
		},
		changeImmonth: (val) => {
			LOsearchForm.immonth = val;
			// if (val === '6') {
			// 	setperiodOptions([
			// 		{ value: '6', label: t('Overview.Last6Months') },
			// 		{ value: '12', label: t('Overview.Last12Months') },
			// 	]);
			// } else if (val === '12') {
			// 	setperiodOptions([{ value: '12', label: t('Overview.Last12Months') }]);
			// }
		},
	}));

	const LOsearchForm = useReactive({
		srchString: '',
		immonth: '3',
		orderType: '',
		orderField: '',
	});

	const inputChangeHandler = useMemoizedFn((e) => {
		LOsearchForm.srchString = e.target.value;
		if (props.isSearch && e.target.value === '') {
			props.searchData(JSON.parse(JSON.stringify(LOsearchForm)), 'reset');
		}
	});

	const cleanText = useMemoizedFn(() => {
		LOsearchForm.srchString = '';
		props.searchData(JSON.parse(JSON.stringify(LOsearchForm)), 'reset');
		setsearchVal('');
	});

	const searchText = useMemoizedFn(() => {
		if (LOsearchForm.srchString.trim() === '') {
			return;
		}
		props.searchData(JSON.parse(JSON.stringify(LOsearchForm)), 'search');
		setsearchVal(LOsearchForm.srchString.trim());
	});

	const [duedateSort, setDueDateSort] = useState('desc hoverdesc');
	const [numberSort, setNumberSort] = useState('hoverdesc');
	const [referenceSort, setReferenceSort] = useState('hoverdesc');
	const [problemSort, setProblemSort] = useState('hoverdesc');
	const [searchVal, setsearchVal] = useState('');

	const sortItems = useMemoizedFn((val) => {
		if (props.data.length === 0) {
			return;
		}
		if (val === 'date') {
			setDueDateSort(
				duedateSort === 'desc hoverdesc' ? 'asc hoverasc' : 'desc hoverdesc'
			);
			setNumberSort('hoverdesc');
			setReferenceSort('hoverdesc');
			setProblemSort('hoverdesc');
			if (duedateSort === 'desc hoverdesc') {
				LOsearchForm.orderType = 'ASC';
				LOsearchForm.orderField = 'NC Date';
				props.searchData(JSON.parse(JSON.stringify(LOsearchForm)), 'other');
			} else if (
				duedateSort === 'asc hoverasc' ||
				duedateSort === 'hoverdesc'
			) {
				LOsearchForm.orderType = '';
				LOsearchForm.orderField = '';
				props.searchData(JSON.parse(JSON.stringify(LOsearchForm)), 'other');
			}
		} else if (val === 'number') {
			setNumberSort(
				numberSort === 'desc hoverdesc' ? 'asc hoverasc' : 'desc hoverdesc'
			);
			setDueDateSort('hoverdesc');
			setReferenceSort('hoverdesc');
			setProblemSort('hoverdesc');
			if (numberSort === 'desc hoverdesc') {
				LOsearchForm.orderType = 'ASC';
				LOsearchForm.orderField = 'NC Number';
				props.searchData(JSON.parse(JSON.stringify(LOsearchForm)), 'other');
			} else if (numberSort === 'asc hoverasc' || numberSort === 'hoverdesc') {
				LOsearchForm.orderType = 'DESC';
				LOsearchForm.orderField = 'NC Number';
				props.searchData(JSON.parse(JSON.stringify(LOsearchForm)), 'other');
			}
		} else if (val === 'reference') {
			setReferenceSort(
				referenceSort === 'desc hoverdesc' ? 'asc hoverasc' : 'desc hoverdesc'
			);
			setDueDateSort('hoverdesc');
			setNumberSort('hoverdesc');
			setProblemSort('hoverdesc');
			if (referenceSort === 'desc hoverdesc') {
				LOsearchForm.orderType = 'ASC';
				LOsearchForm.orderField = 'Ref Doc';
				props.searchData(JSON.parse(JSON.stringify(LOsearchForm)), 'other');
			} else if (
				referenceSort === 'asc hoverasc' ||
				referenceSort === 'hoverdesc'
			) {
				LOsearchForm.orderType = 'DESC';
				LOsearchForm.orderField = 'Ref Doc';
				props.searchData(JSON.parse(JSON.stringify(LOsearchForm)), 'other');
			}
		} else if (val === 'problem') {
			setProblemSort(
				problemSort === 'desc hoverdesc' ? 'asc hoverasc' : 'desc hoverdesc'
			);
			setDueDateSort('hoverdesc');
			setNumberSort('hoverdesc');
			setReferenceSort('hoverdesc');
			if (problemSort === 'desc hoverdesc') {
				LOsearchForm.orderType = 'ASC';
				LOsearchForm.orderField = 'Main Problem';

				props.searchData(JSON.parse(JSON.stringify(LOsearchForm)), 'other');
			} else if (
				problemSort === 'asc hoverasc' ||
				problemSort === 'hoverdesc'
			) {
				LOsearchForm.orderType = 'DESC';
				LOsearchForm.orderField = 'Main Problem';
				props.searchData(JSON.parse(JSON.stringify(LOsearchForm)), 'other');
			}
		}
	});

	const periodChanged = useMemoizedFn((event) => {
		// if (props.data.length === 0) {
		// 	return;
		// }
		LOsearchForm.immonth = event.value;
		props.searchData(JSON.parse(JSON.stringify(LOsearchForm)), 'search');
	});

	return (
		<>
			<div className="boxSearch">
				<input
					type="text"
					value={LOsearchForm.srchString}
					onChange={(e) => inputChangeHandler(e)}
					className="txbSearch"
					placeholder="Search Letters"
				/>
				{props.isSearch && LOsearchForm.srchString !== '' ? (
					<button className="btnCancel" onClick={() => cleanText()}>
						<img alt="Back" src={ICONclose} />
					</button>
				) : (
					<></>
				)}
				<button className="btnSearch" onClick={() => searchText()}>
					<img alt="Back" src={ICONsearch} />
				</button>
			</div>

			<div className="boxPeriod">
				<DropdownList
					options={periodOptions}
					className="ddlPeriod"
					menuClassName="options"
					onChange={(event) => periodChanged(event)}
					controlClassName="lineControl"
					arrowClosed={<img className="iconDown" alt="Expand" src={ICONdown} />}
					arrowOpen={<img className="iconUp" alt="Expand" src={ICONup} />}
					value={LOsearchForm.immonth}
				/>
			</div>
			{props.filtersNumber === 0 ? (
				<button
					className="btnShowFilters"
					onClick={() => props.changeFilterPanel()}
				>
					<img alt="Open Filters" src={ICONfilter} />
				</button>
			) : (
				<Badge
					className="badgecount"
					count={props.filtersNumber}
					offset={[10, 30]}
					style={{ backgroundColor: '#000' }}
					size="default"
				>
					<button
						className="btnShowFilters"
						onClick={() => props.changeFilterPanel()}
					>
						<img alt="Open Filters" src={ICONfilter} />
					</button>
				</Badge>
			)}

			{!responsive.large ? (
				<div className="boxAllExpand">
					<span>{t('Overview.Expand')}</span>
					<button
						className="btnAllExpand"
						onClick={() => props.changeExpandedAll()}
					>
						{props.expandedAll ? (
							<img alt="Shrinked" src={ICONradioON} />
						) : (
							<img alt="Expanded" src={ICONradioOFF} />
						)}
					</button>
				</div>
			) : (
				<Fragment />
			)}

			{(() => {
				if (props.isSearch && searchVal !== '') {
					if (props.data.length !== 0) {
						return (
							<div className="searchresult">
								<p>
									{t('Overview.Yoursearch')}: <b>'{searchVal}'</b>
								</p>
							</div>
						);
					}
				}
			})()}

			{props.data.length ? (
				<Affix style={{ width: '100%' }}>
					<div className="boxTitleswrap" id="stickyHeader">
						<div className="boxTitles">
							<button
								className={`btnSort duedatesort ${duedateSort}`}
								onClick={() => sortItems('date')}
							>
								{t('Overview.NCDate')}
							</button>
							<button
								className={`btnSort ncnumbersort ${numberSort}`}
								onClick={() => sortItems('number')}
							>
								{t('Overview.NCNumber')}
							</button>
							<button
								className={`btnSort referencesort long ${referenceSort}`}
								onClick={() => sortItems('reference')}
							>
								{t('Overview.ReferenceDoc')}
							</button>
							<button
								className={`btnSort problemsort flexible ${problemSort}`}
								onClick={() => sortItems('problem')}
							>
								{t('Overview.MainProblem')}
							</button>
						</div>
						<div className="boxAllExpand">
							<span>{t('Overview.Expand')}</span>
							<button
								className="btnAllExpand"
								onClick={() => props.changeExpandedAll()}
							>
								{props.expandedAll ? (
									<img alt="Shrinked" src={ICONradioON} />
								) : (
									<img alt="Expanded" src={ICONradioOFF} />
								)}
							</button>
						</div>
					</div>
				</Affix>
			) : (
				<></>
			)}
		</>
	);
});

export default memo(LettersOverviewHeader);
