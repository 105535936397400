import React, { Fragment, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Header from './containers/Header/Header';
import Footer from './containers/Footer/Footer';
import Landing from './components/Landing/Landing';
import AdminHome from './components/AdminHome/index';
import AdminUser from './components/AdminUser/index';
import AdminAnnouncement from './components/AdminAnnouncement/index';
import Register from './components/Register/Register';
import Start from './components/Start/Start';
import Overview from './components/Overview/Overview';
import Support from './components/Support/Support';
import Privacy from './components/Privacy/Privacy';
import FAQ from './components/FAQ/FAQ';
import FAQTemp from './components/FAQ/FAQTemp';
import CM from './components/CM/index';
// import AdminCM from './components/AdminCM/index';
import Detail from './components/Detail/Detail';
import NoAuth from './components/403/403';
import LayerBackground from './components/LayerBackground/LayerBackground';
import DReport from './components/8DReport/index';
import DReportDetail from './components/8DReportDetail/index';
import DetailedGuides from './components/DetailedGuides/index';
import Internal from './components/Internal/index';
import Maintenance from './components/Maintenance/index';
// import FuncAccount from './components/FuncAccount/index';
import './assets/cascades/main.scss';
import './locales/i18n';
import { Spin } from 'antd';
import store from './state/store';
const { Provider } = store;

function App() {
	const [isUserLogin, setIsUserLogin] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	// Background Modifier
	// TODO? Merge
	const [headerImage, setHeaderImage] = useState('');
	const [headerSolid, setHeaderSolid] = useState(false);
	const [headerCover, setHeaderCover] = useState(false);
	const [headerEmpty, setHeaderEmpty] = useState(true);
	// isEmpty: white background
	// isSolid: gray banner area
	// path: image path
	// isCover: image takes the whole view
	const backgroundModifier = (isEmpty, isSolid, path, isCover) => {
		setHeaderImage(path);
		setHeaderSolid(isSolid);
		setHeaderCover(isCover);
		setHeaderEmpty(isEmpty);
	};

	const doLogin = (check) => {
		setIsUserLogin(check);
	};

	const doLoading = (val) => {
		setIsLoading(val);
	};

	return (
		<div className={headerEmpty ? 'App' : 'App alt'}>
			<Provider>
				<BrowserRouter>
					<Spin spinning={isLoading} size="large">
						<Fragment>
							<Header doLogin={doLogin} doLoading={doLoading} />
							<Switch>
								<Route
									path="/"
									exact
									render={() => (
										<Start
											bgHandler={backgroundModifier}
											isUserLogin={isUserLogin}
										/>
									)}
								/>
								<Route
									path="/internal"
									exact
									render={() => (
										<Internal
											bgHandler={backgroundModifier}
											isUserLogin={isUserLogin}
										/>
									)}
								/>
								<Route
									path="/register"
									render={() => <Register bgHandler={backgroundModifier} />}
								/>
								<Route
									path="/overview"
									exact
									render={() => (
										<Overview
											bgHandler={backgroundModifier}
											isUserLogin={isUserLogin}
										/>
									)}
								/>
								<Route
									path="/landing"
									exact
									render={() => (
										<Landing
											bgHandler={backgroundModifier}
											isUserLogin={isUserLogin}
										/>
									)}
								/>
								<Route
									path="/adminhome"
									exact
									render={() => (
										<AdminHome
											bgHandler={backgroundModifier}
											isUserLogin={isUserLogin}
										/>
									)}
								/>
								<Route
									path="/adminuser"
									exact
									render={() => (
										<AdminUser
											bgHandler={backgroundModifier}
											isUserLogin={isUserLogin}
										/>
									)}
								/>
								<Route
									path="/adminannouncement"
									exact
									render={() => (
										<AdminAnnouncement
											bgHandler={backgroundModifier}
											isUserLogin={isUserLogin}
										/>
									)}
								/>

								<Route
									path="/privacy"
									exact
									render={() => <Privacy bgHandler={backgroundModifier} />}
								/>
								<Route
									path="/support"
									exact
									render={() => <Support bgHandler={backgroundModifier} />}
								/>
								<Route
									path="/faqstatic"
									exact
									render={() => <FAQ bgHandler={backgroundModifier} />}
								/>
								<Route
									path="/faq"
									exact
									render={() => <FAQTemp bgHandler={backgroundModifier} />}
								/>
								<Route
									path="/8dreport"
									exact
									render={() => <DReport bgHandler={backgroundModifier} />}
								/>
								<Route
									path="/8dreportdetail"
									exact
									render={() => (
										<DReportDetail bgHandler={backgroundModifier} />
									)}
								/>
								<Route
									path="/detailedguides"
									exact
									render={() => (
										<DetailedGuides bgHandler={backgroundModifier} />
									)}
								/>
								<Route
									path="/maintenance"
									exact
									render={() => <Maintenance />}
								/>
								{/* <Route
									path="/configuration"
									exact
									render={() => <FuncAccount bgHandler={backgroundModifier} />}
								/>
								<Route
									path="/admincm"
									exact
									render={() => <AdminCM bgHandler={backgroundModifier} />}
								/> */}
								<Route
									path="/detail"
									exact
									render={() => (
										<Detail
											bgHandler={backgroundModifier}
											isUserLogin={isUserLogin}
										/>
									)}
								/>
								<Route
									path="/contentmanagement"
									exact
									render={() => (
										<CM
											bgHandler={backgroundModifier}
											isUserLogin={isUserLogin}
										/>
									)}
								/>
								<Route path="/403" exact render={() => <NoAuth />} />
								<Route path="*" render={() => <NoAuth />} />
							</Switch>
							<LayerBackground
								image={headerImage}
								cover={headerCover}
								solid={headerSolid}
							/>
							<Footer />
						</Fragment>
					</Spin>
				</BrowserRouter>
			</Provider>
		</div>
	);
}

export default App;
