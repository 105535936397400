import React, { useState, memo, Fragment } from 'react';
import { useMemoizedFn, useMount, useReactive } from 'ahooks';
import axios from '../../../axios-custom';
import store from '../../../state/store';
import ICONclose from '../../../assets/icons/close.svg';
import { Modal } from 'antd';
import '../../../../node_modules/antd/lib/modal/style/index.css';
import Dexie from 'dexie';
import DropdownList from 'react-dropdown';
import ICONup from '../../../assets/icons/arrow-up.svg';
import ICONdownBlue from '../../../assets/icons/arrowdownblue.svg';
import { useTranslation } from 'react-i18next';

const { useModel } = store;

const db = new Dexie('NCM_database');
db.version(1).stores({ nc: '++id,qmnum,report,lang' });

const langData = [
	{ value: 'en', label: 'English' },
	{ value: 'de', label: 'Deutsch' },
	{ value: 'fr', label: 'Français' },
	{ value: 'es', label: 'Español' },
	{ value: 'pt', label: 'Português' },
	{ value: 'it', label: 'Italian' },
	{ value: 'zh-Hans', label: 'Chinese Simplified' },
	{ value: 'ja', label: 'Japanese' },
];

const Translate = (
	{ text, changeLongtext, qmnum, report, detectLang },
	ref
) => {
	const { t } = useTranslation();
	// eslint-disable-next-line no-unused-vars
	const [state, dispatchers] = useModel('global');
	const { toggleLoading } = dispatchers;
	const [isModalVisible, setIsModalVisible] = useState(false);

	// useImperativeHandle(ref, () => ({
	// 	disableLang: (lang) => {
	// 		let findIndex = langData.findIndex((item) => item.value === lang);
	// 		langData.splice(findIndex, 1);
	// 	},
	// }));

	useMount(async () => {
		const indexFind = await db.nc.get({ qmnum: qmnum, report: report });
		console.log('indexFind', indexFind);
		//
		data.val = indexFind ? indexFind.lang : '';
		//
		data.text = text;
	});

	const data = useReactive({
		text: '',
		val: '',
		options: [],
	});

	const openModal = useMemoizedFn(async () => {
		// data.text = text;
		setIsModalVisible(true);
		//
		let temp = JSON.parse(JSON.stringify(langData));
		if (detectLang) {
			let findIndex = temp.findIndex((item) => item.value === detectLang);
			console.log(findIndex);
			temp.splice(findIndex, 1);
			data.options = temp;
		} else {
			data.options = temp;
		}
	});

	const handleChange = useMemoizedFn(async (e) => {
		if (e.value === '') {
			return;
		}
		data.val = e.value;
	});

	const translateHandle = useMemoizedFn(async () => {
		const indexFind = await db.nc.get({ qmnum: qmnum, report: report });
		console.log('indexFind', indexFind);
		if (indexFind) {
			await db.nc.update(indexFind.id, { lang: data.val });
		} else {
			await db.nc.add({ qmnum: qmnum, report: report, lang: data.val });
		}
		toggleLoading();
		let translateObj = {
			to: data.val,
			texts: [
				{
					Text: data.text,
				},
			],
		};
		// if (data.val === 'fr') {
		// 	let res = await axios.post(
		// 		'/feedbackServices/translator/translateV2',
		// 		translateObj
		// 	);
		// 	changeLongtext(res.data[0].translations[0].text);
		// } else {
		// 	let res = await axios.post(
		// 		'/feedbackServices/translator/translate',
		// 		translateObj
		// 	);
		// 	changeLongtext(res.data[0].translations[0].text);
		// }
		let res = await axios.post(
			'/feedbackServices/translator/translate',
			translateObj
		);
		changeLongtext(res.data[0].translations[0].text);
		setIsModalVisible(false);
		toggleLoading();
	});

	const resetTran = useMemoizedFn(async (val) => {
		const indexFind = await db.nc.get({ qmnum: qmnum, report: report });
		console.log('indexFind', indexFind);
		if (indexFind) {
			await db.nc.delete(indexFind.id);
		}
		data.val = '';
		changeLongtext(data.text);
	});

	return (
		<Fragment>
			<Modal
				open={isModalVisible}
				className="tanslateModal"
				footer={null}
				closable={false}
				getContainer={false}
			>
				<div className="contentinto">
					<div className="title">{t('Detail.Translatethemessage')}</div>
					<div className="selectlang">{t('Detail.Selectlanguage')}</div>
					<div className="langdrop">
						<DropdownList
							options={data.options}
							className="ddlFeedbackNofilter mainCause"
							placeholder={t('Detail.Selectlanguage')}
							menuClassName="options"
							onChange={(event) => handleChange(event)}
							controlClassName="lineControl"
							arrowClosed={
								<img className="iconDown" alt="Expand" src={ICONdownBlue} />
							}
							arrowOpen={<img className="iconUp" alt="Expand" src={ICONup} />}
							value={data.val}
						/>
					</div>
					<div className="langbuttonwrap">
						<button className="langbutton" onClick={() => translateHandle()}>
							{t('Detail.TranslateNow')}
						</button>
					</div>
				</div>
				<div className="closeicon" onClick={() => setIsModalVisible(false)}>
					<img alt="close" src={ICONclose} />
				</div>
			</Modal>
			<div className="translatewrap">
				{data.val === '' ? (
					<span className="backtranslatebutton">
						<span className="translatebutton" onClick={() => openModal()}>
							{t('Detail.Translate')}
						</span>
					</span>
				) : (
					<span className="backtranslatebutton">
						<span className="translatetext">{t('Detail.Texttranslated')}</span>
						<span className="translatebutton" onClick={() => resetTran()}>
							{t('Detail.Vieworiginal')}
						</span>
					</span>
				)}
			</div>
		</Fragment>
	);
};

export default memo(Translate);
