import React, { Fragment, memo, useState } from 'react';
import { downloadFileByBase64 } from '../../../utils/util';
import { Tooltip } from 'antd';
import '../../../../node_modules/antd/lib/tooltip/style/index.css';
import ICONdown from '../../../assets/icons/arrow-down.svg';
import ICONup from '../../../assets/icons/arrow-up.svg';
import { useTranslation } from 'react-i18next';
import { useMount } from 'ahooks';
import axios from '../../../axios-custom';
import store from '../../../state/store';
const { useModel } = store;

const Attachment = ({ etAttachmentDTO, report, qmnum }) => {
	// eslint-disable-next-line no-unused-vars
	const [state, dispatchers] = useModel('global');
	const { toggleLoading } = dispatchers;

	const [allFiles, setAllfiles] = useState([]);
	const [showAll, setShowAll] = useState(false);
	const { t } = useTranslation();

	useMount(() => {
		if (etAttachmentDTO.length > 3) {
			let temp = [];
			for (let i = 0; i < 3; i++) {
				temp.push(etAttachmentDTO[i]);
			}
			setAllfiles(temp);
		} else {
			setAllfiles(etAttachmentDTO);
		}
	});

	const toggleShowAll = () => {
		if (showAll) {
			setShowAll(false);
			let temp = [];
			for (let i = 0; i < 3; i++) {
				temp.push(etAttachmentDTO[i]);
			}
			setAllfiles(temp);
		} else {
			setShowAll(true);
			setAllfiles(etAttachmentDTO);
		}
	};

	const fileType = (name) => {
		let temp = name.split('.');
		let file = temp[temp.length - 1].toUpperCase();
		if (file.includes('DOC') || file.includes('DOCX')) {
			return 'ext docx';
		} else if (file.includes('PDF')) {
			return 'ext pdf';
		} else if (file.includes('JPG') || file.includes('JPEG')) {
			return 'ext jpg';
		} else if (file.includes('PNG')) {
			return 'ext png';
		} else if (file.includes('XLS')) {
			return 'ext xlsx';
		} else if (file.includes('MSG')) {
			return 'ext msg';
		} else if (file.includes('PPT')) {
			return 'ext ppt';
		} else if (file.includes('TXT')) {
			return 'ext txt';
		} else if (file.includes('MP4')) {
			return 'ext mp4';
		} else {
			return 'ext';
		}
	};

	const downloadAtachFile = async (filename) => {
		let obj = {
			ncNumber: qmnum,
			report: report,
			filename: filename,
		};
		toggleLoading();
		let temp = await axios.post(
			'/feedbackServices/portalSap/downloadAttachment',
			obj
		);
		toggleLoading();
		if (temp.data.byteToString && temp.data.filename) {
			downloadFileByBase64(temp.data.byteToString, temp.data.filename);
		}
	};

	const funcbottom = () => {
		if (etAttachmentDTO.length > 3) {
			if (showAll) {
				return (
					<div className="morefiles" onClick={() => toggleShowAll()}>
						<span className="downicon">
							<img alt="" src={ICONup} />
						</span>
						<span className="downicontext">
							{`${etAttachmentDTO.length} ${t('Detail.attachments')}`}
						</span>
					</div>
				);
			} else {
				return (
					<div className="morefiles" onClick={() => toggleShowAll()}>
						<span className="downicon">
							<img alt="" src={ICONdown} />
						</span>
						<span className="downicontext">
							{`${t('Detail.Showallattachments')} ${etAttachmentDTO.length} ${t(
								'Detail.attachments'
							)}`}
						</span>
					</div>
				);
			}
		} else {
			return <Fragment />;
		}
	};

	return (
		<Fragment>
			<ul
				className={
					etAttachmentDTO.length > 3
						? 'lstFiles readOnly print_hide'
						: 'lstFilesCenter readOnly print_hide'
				}
			>
				{allFiles.map((item, index) => {
					return (
						<Tooltip
							title={item.filename}
							placement="top"
							key={index}
							overlayClassName="filetooltip"
						>
							<li onClick={() => downloadAtachFile(item.filename)}>
								<span className={fileType(item.filename)}></span>
								<span className="file">{item.filename}</span>
							</li>
						</Tooltip>
					);
				})}
			</ul>
			{funcbottom()}
		</Fragment>
	);
};

export default memo(Attachment);
